import { Dispatch, SetStateAction, useState } from 'react'

export type UsePaginationValue = {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  onClickPrevPage: () => void
  onClickNextPage: () => void
  onClickFirstPage: () => void
  onClickLastPage: () => void
  rowsPerPage: number
  onChangeRowNumber: (selectedRowNumber: number) => void
  changeCurrentPage: (page: number) => void
  setRowsPerPage:Dispatch<SetStateAction<number>>
}

const usePagination = (
  defaultRowsPerPage: number,
  defaultCurrentPage: number,
  defaultPageCount: number
): UsePaginationValue => {
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage)
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage)

  const onClickPrevPage = (): void => {
    setCurrentPage((prev) => prev - 1)
  }

  const onClickNextPage = (): void => {
    setCurrentPage((prev) => prev + 1)
  }

  const onClickFirstPage = (): void => {
    setCurrentPage(0)
  }

  const onClickLastPage = (): void => {
    setCurrentPage(Math.ceil(defaultPageCount / rowsPerPage) - 1)
  }

  const onChangeRowNumber = (selectedRowNumber: number): void => {
    setRowsPerPage(selectedRowNumber)
    setCurrentPage(0)
  }

  const changeCurrentPage = (page: number): void => {
    setCurrentPage(page)
  }

  return {
    currentPage,setRowsPerPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    changeCurrentPage,
    onClickFirstPage,
    onClickLastPage,
  }
}

export default usePagination
