import apiSlice from '../../api/apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [],
})

export interface SalesRegion {
  id: number;
  salesRegion: string;
  salesRegionId: number;
  salesRegionFullName: string;
  server_region_name: string;
}

export interface ApiResponse {
  resultCode: string;
  totalCount: number;
  details: SalesRegion[];
}

export interface serverRegionType{
  id: number;
  server_region_id: number;
  server_region_name: string;
}

export interface ServerRegionApiResponse{
  resultCode: string;
  totalCount: number;
  details: serverRegionType[];
}

export const serverRegionSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    regionList: builder.query<ApiResponse, void>({
      query: () => ({
        url: `/private/region-list`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
    }),

    serverRegionList : builder.query<ServerRegionApiResponse, void>({
      query: () => ({
        url: `/private/server-region-list`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
    })
  })
})

export const { useLazyRegionListQuery , useServerRegionListQuery } =
serverRegionSlice


