import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneIcon from '@mui/icons-material/Done'
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Popper,
  ClickAwayListener,
  Paper,
  Skeleton,
  Button
} from '@mui/material'
import {
  serverRegionType,
  useServerRegionListQuery
} from '../../../state/features/serverRegion/serverRegionSlice'
import { useStyles } from '../../../themes/index'
import { ReactComponent as CloseIcon } from '../../../images/icon/close-small.svg'

export interface RegionFilterProps {
  setSelectedServerRegion: Dispatch<SetStateAction<serverRegionType[]>>
  titleText?: string
  topMargin?: string
  isDisabled?: boolean
  serverRegionIds?: number
  width?: string
}

const RegionFilter: React.FC<RegionFilterProps> = ({
  setSelectedServerRegion,
  titleText,
  topMargin,
  isDisabled,
  serverRegionIds,
  width
}) => {
  const classes = useStyles()

  const { data: serverRegionList, isFetching: regionListIsReloading } =
    useServerRegionListQuery()

  const [selectAnchor, setSelectAnchor] = useState<null | HTMLElement>(null)
  const [selectOpen, setSelectOpen] = useState<boolean>(false)
  const [selectedRegion, setSelectedRegion] = useState<serverRegionType[]>([])

  // Select tag status toggling
  const handleSelectRegionOpen = (event: MouseEvent | TouchEvent) => {
    event.preventDefault()
    if (selectOpen) {
      setSelectOpen(false)
    } else {
      setSelectOpen(true)
    }
  }
  // select box click status
  const handleSelectBoxClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setSelectAnchor(event.currentTarget)
    if (selectOpen) {
      setSelectOpen(false)
    } else {
      setSelectOpen(true)
    }
  }

  const handleSelectRegion = (info: serverRegionType) => {
    const index = selectedRegion.findIndex((region) => region.id === info.id)
    if (index !== -1) {
      const updatedRegions = [...selectedRegion]
      updatedRegions.splice(index, 1)
      setSelectedRegion(updatedRegions)
    } else {
      setSelectedRegion([...selectedRegion, info])
    }
  }
  const handleItemRemove = (info: serverRegionType) => {
    const index = selectedRegion.findIndex((region) => region.id === info.id)

    if (index !== -1) {
      const updatedRegions = [...selectedRegion]
      updatedRegions.splice(index, 1)
      setSelectedRegion(updatedRegions)

      if (!selectOpen) {
        setSelectedServerRegion(updatedRegions)
      }
    }
  }
  const handleAllSelect = () => {
    if (serverRegionList?.details) {
      setSelectedRegion(serverRegionList?.details)
    }
  }
  const handleAllClear = () => {
    setSelectedRegion([])
  }

  useEffect(()=>{
    const regionList = [
      {
        id: 1,
        server_region_id: 1,
        server_region_name: 'US'
      },
      {
        id: 2,
        server_region_id: 2,
        server_region_name: 'JP'
      },
      {
        id: 3,
        server_region_id: 3,
        server_region_name: 'EU'
      }
    ]
    serverRegionIds && setSelectedRegion(regionList.filter(({id})=>(serverRegionIds === id)))
  }, [serverRegionIds])

  useEffect(() => {
    if (!selectOpen) {
      setSelectedServerRegion(selectedRegion)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOpen])

  return (
    <Box sx={{ marginTop: topMargin || '-25px' }}>
      {titleText != '' && (
        <Typography className={classes.regionSelectTitle}>
          {titleText || 'Filter by Server Regions'}
        </Typography>
      )}
      <FormControl sx={{ width: width || '395px', height: '38px' }}>
        <Box
          onClick={isDisabled ? () => null : handleSelectBoxClick}
          sx={{
            border: selectOpen ? '1px solid #9AA5B1' : '1px solid #DDDDDD',
            cursor: isDisabled ? 'default !important' : 'pointer'
          }}
          className={classes.regionSelectBox}
        >
          <Box className={classes.selectedRegionCard}>
            {selectedRegion.length > 0 ? (
              selectedRegion.map((item: serverRegionType) => (
                <Box
                  key={item?.server_region_name}
                  className={classes.selectedRegionBox}
                  onClick={isDisabled ? () => null : (event) => {
                    event.stopPropagation()
                  }}
                >
                  {item?.server_region_name}
                  <Box
                    className={classes.selectedRegionCrossIcon}
                    onClick={isDisabled ? () => null : (event) => {
                      event.stopPropagation()
                      handleItemRemove(item)
                    }}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              ))
            ) : (
              <Typography className={classes.regionSelectPlaceholder}>
                Select Server Regions
              </Typography>
            )}
          </Box>
          <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px' }} />
        </Box>
        <Popper
          placement='bottom-start'
          anchorEl={selectAnchor}
          open={selectOpen}
          className={classes.selectRegionModel}
        >
          <ClickAwayListener onClickAway={handleSelectRegionOpen}>
            <Paper className={classes.selectRegionPopperBox}>
              <Box className={classes.allBoxCard}>
                <Button
                  className={classes.allButton}
                  onClick={isDisabled ? () => null : handleAllSelect}
                  disabled={
                    serverRegionList &&
                    serverRegionList?.details?.length <= selectedRegion.length
                  }
                >
                  All Select
                </Button>
                <Button
                  onClick={isDisabled ? () => null : handleAllClear}
                  className={classes.allButton}
                  disabled={!(selectedRegion.length >= 1)}
                >
                  All Clear
                </Button>
              </Box>
              <Box>
                {regionListIsReloading ? (
                  <>
                    <MenuItem className={classes.regionItemSkeletonBox} sx={{}}>
                      <Skeleton className={classes.regionItemSkeleton} />
                    </MenuItem>
                    <MenuItem
                      className={classes.regionItemSkeletonBox}
                      sx={{
                        borderBottomLeftRadius: '6px',
                        borderBottomRightRadius: '6px'
                      }}
                    >
                      <Skeleton className={classes.regionItemSkeleton} />
                    </MenuItem>
                  </>
                ) : (
                  serverRegionList &&
                  serverRegionList?.details?.length > 0 &&
                  serverRegionList?.details.map((item: serverRegionType) => {
                    const isItemSelected = selectedRegion.some(
                      (region: serverRegionType) => region?.id === item.id
                    )

                    return (
                      <MenuItem
                        key={item?.id}
                        className={classes.regionItem}
                        sx={{
                          backgroundColor: isItemSelected
                            ? '#FAFAFA'
                            : '#ffffff',
                          width: width
                            ? `calc(${width.concat(' - 2px')})`
                            : '393px'
                        }}
                        value={item?.id}
                        onClick={isDisabled ? () => null : () => {
                          handleSelectRegion(item)
                        }}
                      >
                        <Typography
                          className={classes.regionItemText}
                          sx={{
                            width: isItemSelected ? '208px' : '228px',
                            color: isItemSelected ? '#232F3E' : '#6C7778'
                          }}
                        >
                          {item?.server_region_name}
                        </Typography>

                        {isItemSelected && (
                          <DoneIcon
                            sx={{
                              height: '20px',
                              width: '20px',
                              color: '#009DE1'
                            }}
                          />
                        )}
                      </MenuItem>
                    )
                  })
                )}
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </FormControl>
    </Box>
  )
}

export default RegionFilter
