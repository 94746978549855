import { makeStyles } from '@mui/styles'

const useDeviceUrlMappingStyles = makeStyles(() => ({
  loaderParent: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255, 0.7)',
    width: '100%',
    height: '100%',
    zIndex: 1,
  }
}))

export default useDeviceUrlMappingStyles
