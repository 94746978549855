/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom'
import { ACCESSCONTROL_MAP, PAGE_PATH_NAME } from '../constants/constants'
import type { LoginInfoState } from '../state/slice/loginInfoSlice'
import type { LoginInfoParameter } from '../types/apis/apiCommon'
import { ERROR_MESSAGE } from '../constants/messageConstants'

/**
 * 指定された権限値から権限名を取得
 * @param accesscontrolId
 * @returns
 */
export const fetchAccesscontrolName = (accesscontrolId: string): string => {
  const accesscontrolInfo = ACCESSCONTROL_MAP.filter(
    (info) => accesscontrolId === info.id
  )[0]
  if (accesscontrolInfo) {
    return accesscontrolInfo.name
  }

  return ''
}

/**
 * 各APIのリクエストパラメータに指定するログイン情報を作成
 * @returns
 */
export const createLoginInfoParam = (
  loginInfo: LoginInfoState
): LoginInfoParameter => {
  const loginInfoParam: LoginInfoParameter = {
    admin_id: loginInfo.adminId,
    fname: loginInfo.fname,
    lname: loginInfo.lname,
    accesscontrol_id: loginInfo.accesscontrolId
  }

  return loginInfoParam
}

/**
 * ［エラー］画面に遷移して「統合認証APIの呼び出しに失敗」エラーメッセージを表示
 * @param navigate
 */
export const moveErrorViewAtAuthError = (navigate: NavigateFunction): void => {
  navigate(PAGE_PATH_NAME.ERROR, {
    state: ERROR_MESSAGE.MESSAGE.AUTH_ERROR,
    replace: true
  })
}

/**
 * ［エラー］画面に遷移して「有効期限切れエラー」エラーメッセージを表示
 * @param navigate
 */
export const moveErrorViewAtTimeout = (navigate: NavigateFunction): void => {
  navigate(PAGE_PATH_NAME.ERROR, {
    replace: true,
    state: ERROR_MESSAGE.MESSAGE.TIMEOUT
  })
}

/**
 * 指定されたオブジェクトの型が CompanyState かチェック
 * @param target CompanyState([法人詳細]画面遷移時に渡す値)
 * @returns true=CompanyState型 / false=左記以外の型
 */
// export const isCompanyStateType = (
//   target: CompanyState | unknown
// ): target is CompanyState => {
//   if (!target) {
//     return false
//   }

//   if (target === undefined) {
//     return false
//   }

//   if (typeof (target as CompanyState).corpId !== 'string') {
//     return false
//   }

//   return true
// }

/**
 * 指定された値が プリミティブ型 か否か
 * @param value
 * @returns
 */
export const isPrimitive = (
  value: unknown
): value is string | boolean | number | symbol | bigint =>
  typeof value === 'string' ||
  typeof value === 'boolean' ||
  typeof value === 'number' ||
  typeof value === 'symbol' ||
  typeof value === 'bigint'

/**
 * Does the specified primitive object include the target character string?
 *
 * @param target プリミティブ型のオブジェクト
 * @param targetText 検索対象の文字列
 * @returns
 */
export const containStringInPrimitive = (
  target: string | boolean | number | symbol | bigint,
  targetText: string
): boolean => {
  if (typeof target === 'string') {
    return (
      target
        .toLowerCase()
        .indexOf(decodeURIComponent(targetText).toLowerCase()) !== -1
    )
  }

  return target.toString().indexOf(targetText) !== -1
}

/**
 * 指定された値が Object か否か
 *
 * @param value
 * @returns
 */
export const isObject = (value: unknown): value is Record<string, unknown> =>
  value !== null && typeof value === 'object'

/*
 * Does the specified object (array or object) include the target character string
 *
 * @param target オブジェクト
 * @param searchText 検索対象の文字列
 * @param filterOutKeys フィルタリング対象外のkey配列
 * @returns
 */
export const containString = (
  target: unknown,
  searchText: string,
  filterOutKeys: string[]
): boolean => {
  // If it was an array
  if (Array.isArray(target)) {
    let result = false
    const targetUnknown: unknown[] = target
    for (let i = 0; i < targetUnknown.length; i += 1) {
      const value = targetUnknown[i]
      if (isPrimitive(value)) {
        result = containStringInPrimitive(value, searchText)
      } else {
        result = containString(value, searchText, filterOutKeys)
      }
      if (result) {
        return true
      }
    }
  }
  // objectだった場合
  if (isObject(target)) {
    let result = false

    Object.entries(target).some((entry) => {
      const [key, value] = entry

      // フィルタリング対象外のkeyの場合はスキップ
      if (filterOutKeys.includes(key)) {
        // 結果がtrueならループから抜ける
        return result === true
      }

      if (isPrimitive(value)) {
        result = containStringInPrimitive(value, searchText)
      } else {
        result = containString(value, searchText, filterOutKeys)
      }

      // 結果がtrueならループから抜ける
      return result === true
    })

    return result
  }

  if (isPrimitive(target)) {
    return containStringInPrimitive(target, searchText)
  }

  return false
}

/*
 * 指定されたリストをもとに、ページ総数を計算して返却
 *
 * @param originalPageList 元となるリスト
 * @param maxRomNumber 1ページに表示する最大行数
 * @returns ページ総数
 */
export const createTotalPageNumber = (
  originalPageList: unknown[],
  maxRomNumber: number
): number => {
  const rowLength: number = Object.keys(originalPageList).length
  if (maxRomNumber > rowLength) {
    return 1
  }

  const pageNumber: number = Math.ceil(rowLength / maxRomNumber)

  return pageNumber
}

export const convertBlockUnderscoreTextIntoCamelCaseWithSpace = (
  text: string
) => {
  if (text === 'RE_INVITED') {
    // const formattedValue = text.replace(/([A-Z]+)/g, "-$1").replace(/^-/, "").replace(/-/, " ");
    const formattedValue1 = text.substring(0, 2)
    const formattedValue2 = text.substring(3, text.length)
    const finalValue1 =
      formattedValue1.charAt(0).toUpperCase() +
      formattedValue1.slice(1).toLowerCase()
    const finalValue2 =
      formattedValue2.charAt(0).toUpperCase() +
      formattedValue2.slice(1).toLowerCase()
    const result = `${finalValue1}-${finalValue2}`

    return result
  }

  const result = text
    .replace('_', ' ')
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word
      }

      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')

  return result
}

export const arrayFill = (start: number, end: number) => {
  const arr: number[] = []
  for (let index = start; index < end; index += 1) {
    arr.push(index)
  }

  return arr
}
interface JsonDataItem {
  'Corp Id': string
  'Corp Name': string
  'Sales Region': string
  'eSIM Valid Duration': string
  IMEI1: string
  IMEI2: string
  'Product Type': string
  Mode: string
  'Overwrite Sim': string
  [key: string]: string
}

export const arrayToObject = (arr: string[][]): JsonDataItem[] => {
  try {
    const jsonData: JsonDataItem[] = []
    for (let i = 0; i < arr.length; i += 1) {
      const data: string[] = arr[i]
      const row: JsonDataItem = {
        'Corp Id': data[0].replace(/\r/g, ''),
        'Corp Name': data[1].replace(/\r/g, ''),
        'Sales Region': data[2].replace(/\r/g, ''),
        'eSIM Valid Duration': data[3].replace(/\r/g, ''),
        IMEI1: data[4].replace(/\r/g, ''),
        IMEI2: data[5].replace(/\r/g, ''),
        'Product Type': data[6].replace(/\r/g, ''),
        Mode: data[7].replace(/\r/g, ''),
        'Overwrite Sim': data[8].replace(/\r/g, '')
      }
      jsonData.push(row)
    }

    return jsonData
  } catch (error) {
    return []
  }
}

const checkCSVContent = (content: string) => {
  const rows = content.split('\n')
  const regex = /^"([^"]*)"(,"([^"]*)")*$/

  // eslint-disable-next-line no-restricted-syntax
  for (const row of rows) {
    if (!regex.test(row.trim())) {
      return false
    }
  }

  return true
}

const findEmptyRow = (content: string) => {
  const rows = content.split('\n')

  // eslint-disable-next-line no-restricted-syntax
  for (const row of rows) {
    if (row === '') {
      return true
    }
  }

  return false
}

export const csvFileContent = async (file: File) => {
  const content = await file?.text()

  const quoteCheck = checkCSVContent(content)
  const hasEmptyRow = findEmptyRow(content)

  const rows: string[][] = []
  let currentRow: string[] = []
  let currentField = ''
  let insideQuotes = false

  // eslint-disable-next-line no-restricted-syntax
  for (const char of content) {
    if (char === '"') {
      insideQuotes = !insideQuotes
    } else if (char === ',' && !insideQuotes) {
      currentRow.push(currentField)
      currentField = ''
    } else if (char === '\n' && !insideQuotes) {
      currentRow.push(currentField)
      rows.push(currentRow)
      currentRow = []
      currentField = ''
    } else {
      currentField += char
    }
  }

  // Push the last field and row if any
  if (currentField) currentRow.push(currentField)
  if (currentRow.length > 0) rows.push(currentRow)

  return { csvFileContentConverted: rows, content, quoteCheck , hasEmptyRow}
}

export const formattedSize = (size: string, precision = 1) => {
  const splittedSize = size.split(' ')
  const y = splittedSize[0]
  const y_number = parseFloat(y)

  if (splittedSize.length === 2) {
    const formattedValue = y_number.toFixed(precision)

    return {
      value: y_number,
      string: `${formattedValue} ${splittedSize[1]}`
    }
  }

  return {
    value: y_number,
    string: size
  }
}


export const getFormattedeSIMValidDuration = (value: string | number | undefined , showDays = true) => {
  if (value || value === 0) {
    const numberValue = Number(value)


    if (numberValue === 9999) {
      return 'Infinite'
    }

    return showDays? `${numberValue} days` : numberValue
  }

  return '-'
}