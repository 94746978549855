import { IDeviceImportListResponse,  deviceImportSearchParamType } from "../../../types/deviceimport";
import apiSlice from "../../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ["DeviceImportList"],
});

export const deviceImportSlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        deviceImportList: builder.query<IDeviceImportListResponse, deviceImportSearchParamType>({

            
            query: ({
                deviceImportHistorySearchParam,
                currentPageNumber,
                rowLimitPerPage,
                colName,
                sortDirection,
                serverRegionIdList
            }) => ({
                url: `/private/deviceimporthistorylist?deviceImportHistorySearchParam=${deviceImportHistorySearchParam as string}&currentPageNumber=${currentPageNumber as number}&rowLimitPerPage=${rowLimitPerPage as number}&colName=${colName || ""}&sortDirection=${sortDirection as string}&serverRegionIdList=${serverRegionIdList}`,
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ["DeviceImportList"],
        }),
        registerDevice: builder.mutation<any, FormData>({
            query: body => ({
                url: "/private/register-devices",
                method: "POST",
                body,
            }),
            invalidatesTags: (result) => result ? ["DeviceImportList"] : []
        }),
    }),
});

export const { useLazyDeviceImportListQuery, useRegisterDeviceMutation} = deviceImportSlice;
