import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { styled, Skeleton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import PageTemplate from '../PageTemplate'
import ToggleSwitch from '../../Atoms/ToggleSwitch'
import StatusButton from '../../Atoms/StatusButton'
import { ReactComponent as SuspendIcon } from '../../../images/icon/suspend.svg'
import styles from './index.module.scss'
import { ReactComponent as LostModeIcon } from '../../../images/icon/lost-mode.svg'
import { ReactComponent as FoundModeIcon } from '../../../images/icon/found-device-icon.svg'
import ConfirmSusDialog from '../../Modecules/ConfirmSusDialog/index'
import CustomTooltip from '../../Atoms/Tooltip'
import {
  useSuspendDeviceMutation,
  useLazyDeviceDetailQuery,
  IDeviceDetailParams
} from '../../../state/features/devices/deviceSlice'
import { getStatusColor } from '../../../utils/datatable/dataTableUtil'
import { ptColors } from '../../../themes/settings/_colors'
import { IDeviceDetailResponse } from '../../../types/device'
import { getFormattedeSIMValidDuration } from '../../../utils/utility'

const StyledCard = styled('div')({
  backgroundColor: ptColors.white,
  border: `1px solid ${ptColors.harp}`,
  borderRadius: '8px',
  padding: '24px 32px 32px'
})

const StyledTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  lineHeight: '20px'
})

const StyledTextHeader = styled('span')({
  fontWeight: 'bold'
})

const DeviceDetails: React.FC = () => {
  const location = useLocation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const [
    fetchDeviceDetailAPI,
    {
      data: deviceDetailsData,
      isLoading: deviceDetailsDataIsLoading,
      error: deviceDetailsFetchError,
      isError: deviceDetailsFetchIsError
    }
  ] = useLazyDeviceDetailQuery()

  const backToPreviousPage = (message: string) => {
    toast.error(message)
    setTimeout(() => {
      window.history.back()
    }, 400)
  }

  useEffect(()=>{
    if(deviceDetailsFetchIsError){
      if(deviceDetailsFetchError.data.message){
        toast.error(deviceDetailsFetchError.data.message as string)
      }else{
        toast.error('Unknown Error')
      }
      setTimeout(() => {
        window.history.back()
      }, 400)
    }
  }, [deviceDetailsFetchError, deviceDetailsFetchIsError])


  const fetchDeviceDetails = () => {
    if (location.state) {
      void fetchDeviceDetailAPI({
        imei1: (location.state as IDeviceDetailParams).imei1
      })
    } else {
      backToPreviousPage('No Device Found')
    }
  }

  useEffect(() => {
    fetchDeviceDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDeviceDetailAPI, location.state])

  const [simSuspension, setSimSuspension] = useState<boolean>(false)
  const [
    suspendDevice,
    {
      // data: suspendData,
      isSuccess: suspendIsSuccess,
      isError: suspendIsError,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error: suspendError,
      isLoading: suspendIsLoading
    }
  ] = useSuspendDeviceMutation()

  useEffect(() => {
    setSimSuspension(deviceDetailsData?.data?.suspension_flag === 1)
  }, [deviceDetailsData?.data?.suspension_flag])

  useEffect(() => {
    if (suspendIsSuccess) {
      fetchDeviceDetails()
      setSimSuspension((prevState) => !prevState)
      toast.success(
        !simSuspension
          ? 'SIM of this device has been successfully suspended.'
          : 'SIM suspension of this device has been successfully lifted.'
      )
      setModalOpen(false)
    }

    if (suspendIsError) {
      // setSimSuspension((prevState)=>!prevState)
      const customError = suspendError as { data: { message: string } }
      if (customError?.data?.message) {
        toast.error(customError.data.message)
      } else {
        toast.error(
          !simSuspension
            ? 'Failed to Suspend Device.'
            : 'Failed to lift the suspension of the device.'
        )
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suspendIsSuccess, suspendError, suspendIsError])

  const getStatusButton = () => {
    if (deviceDetailsDataIsLoading) {
      return (
        <Skeleton
          variant='text'
          animation='wave'
          sx={{ p: '0px', width: '90px', height: '20px' }}
        />
      )
    }
    if (deviceDetailsData?.data.status) {
      return (
        <StatusButton
          fontSize='13px'
          bgColor={getStatusColor(deviceDetailsData?.data?.status)}
          text={deviceDetailsData?.data?.status}
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
          display='inline-block'
          width='fit-content'
          isTooltip
        />
      )
    }

    return '-'
  }
  const getFormattedExpiryDate = (detailsData: IDeviceDetailResponse) => {
    const simExpiryDate = detailsData?.data?.sim_expiry_dt

    if (simExpiryDate?.split('-')[0] === '9999') {
      return 'Infinite'
    }

    if (simExpiryDate) {
      return format(new Date(simExpiryDate), 'MMM d, yyyy  HH:mm')
    }

    return '-'
  }

  return (
    <PageTemplate>
      <ConfirmSusDialog
        open={modalOpen}
        handleClose={() => {
          setModalOpen(false)
        }}
        handleSubmit={async () => {
          await suspendDevice({
            device_id: deviceDetailsData?.data.device_id,
            imei1: deviceDetailsData?.data.imei1,
            imei2: deviceDetailsData?.data.imei2
              ? deviceDetailsData?.data.imei2
              : null,
            product_type: deviceDetailsData?.data.product_type,
            suspension_flag:
              deviceDetailsData?.data?.suspension_flag === 1 ? 0 : 1
          })
        }}
        icon={!simSuspension ? LostModeIcon : FoundModeIcon}
        title={
          !simSuspension
            ? 'Do you want to suspend this device?'
            : 'Do you want to lift the suspension of this device’s SIM?'
        }
        description={
          !simSuspension
            ? 'Click “Yes” to enable Suspension.'
            : 'Click “Yes” to disable Suspension.'
        }
        cancelText='No'
        successText='Yes'
        isModalTop
        isLoading={suspendIsLoading}
      />
      <div className={styles.container}>
        <StyledCard className={styles.card_left}>
          <StyledTextContainer>
            <StyledTextHeader>IMEI1</StyledTextHeader>
            {/* <CustomTooltip toolTipTitle="861536030196001" maxWidth={110} lineHeight="unset"/> */}
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <span>
                {deviceDetailsData?.data?.imei1
                  ? deviceDetailsData?.data?.imei1
                  : '-'}
              </span>
            )}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>IMEI2</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <span>
                {deviceDetailsData?.data?.imei2
                  ? deviceDetailsData?.data?.imei2
                  : '-'}
              </span>
            )}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>Corporate ID</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.corp_id
                    ? deviceDetailsData?.data?.corp_id
                    : '-'
                }
                maxWidth={200}
                lineHeight='unset'
              />
            )}
            {/* <span>rVvw2TdBsxLmF0Gh8cUv</span> */}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>Corporate Name</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.company
                    ? deviceDetailsData?.data?.company
                    : '-'
                }
                maxWidth={250}
                lineHeight='unset'
              />
            )}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>Device Name</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.device_name
                    ? deviceDetailsData?.data?.device_name
                    : '-'
                }
                maxWidth={180}
                lineHeight='unset'
              />
            )}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader sx={{ whiteSpace: 'nowrap' }}>
              Product Model
            </StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <span>
                {deviceDetailsData?.data?.product_type
                  ? deviceDetailsData?.data?.product_type
                  : '-'}
              </span>
            )}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader sx={{ whiteSpace: 'nowrap' }}>
              Mode
            </StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <span>
                {deviceDetailsData?.data?.mode_name
                  ? deviceDetailsData?.data?.mode_name
                  : '-'}
              </span>
            )}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>ICCID1</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.iccid1
                    ? deviceDetailsData.data?.iccid1
                    : '-'
                }
                maxWidth={180}
                lineHeight='unset'
              />
            )}
            {/* <span>8942310019004270104</span> */}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>ICCID2</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.iccid2
                    ? deviceDetailsData.data?.iccid2
                    : '-'
                }
                maxWidth={180}
                lineHeight='unset'
              />
            )}
            {/* <span>8942310019004270104</span> */}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>Software Ver.</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.software_version
                    ? deviceDetailsData?.data?.software_version
                    : '-'
                }
                maxWidth={180}
                lineHeight='unset'
              />
            )}
            {/* <span></span> */}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader sx={{ whiteSpace: 'nowrap' }}>
              Date Last Used
            </StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.latest_trans_dt
                    ? format(
                        new Date(deviceDetailsData?.data?.latest_trans_dt),
                        'MMM d, yyyy  HH:mm'
                      )
                    : '-'
                }
                maxWidth={130}
                lineHeight='unset'
              />
            )}
            {/* <span>Oct 21, 2022 23:30</span> */}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>Status</StyledTextHeader>
            {getStatusButton()}
          </StyledTextContainer>

          <StyledTextContainer>
            <StyledTextHeader sx={{ whiteSpace: 'nowrap' }}>
              Sales Region
            </StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.sales_region
                    ? deviceDetailsData?.data?.sales_region
                    : '-'
                }
                maxWidth='95%'
                lineHeight='unset'
              />
            )}
            {/* <span>Oct 21, 2022 23:30</span> */}
          </StyledTextContainer>

          <StyledTextContainer>
            <StyledTextHeader sx={{ whiteSpace: 'nowrap' }}>
              Server Region
            </StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  deviceDetailsData?.data?.server_region
                    ? deviceDetailsData?.data?.server_region
                    : '-'
                }
                maxWidth='95%'
                lineHeight='unset'
              />
            )}
            {/* <span>Oct 21, 2022 23:30</span> */}
          </StyledTextContainer>
        </StyledCard>
        <StyledCard className={styles.card_right}>
          <StyledTextContainer>
            <StyledTextHeader>SIM Expiration Date</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <span className={styles.card_right_suspension}>
                <span>
                  {deviceDetailsData &&
                    getFormattedExpiryDate(deviceDetailsData)}
                </span>

                {simSuspension && (
                  <span className={styles.card_right_suspension_warning}>
                    <span className={styles.card_right_suspension_warning_icon}>
                      <SuspendIcon />
                    </span>
                    <span>SIM Suspended</span>
                  </span>
                )}
              </span>
            )}
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledTextHeader>eSIM Valid Duration</StyledTextHeader>
            {deviceDetailsDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{ p: '0px', width: '90px', height: '20px' }}
              />
            ) : (
              <span>
                {getFormattedeSIMValidDuration(
                  deviceDetailsData?.data?.sim_contract_time
                )}
              </span>
            )}
          </StyledTextContainer>
          {deviceDetailsDataIsLoading ? (
            <Skeleton
              variant='text'
              animation='wave'
              sx={{ p: '0px', width: '90px', height: '20px' }}
            />
          ) : (
            (deviceDetailsData?.data?.product_type === 'POCKETALK S2' ||
              deviceDetailsData?.data?.product_type ===
                'POCKETALK S2 PLUS') && (
              <StyledTextContainer style={{ gridColumn: 'span 2' }}>
                <StyledTextHeader>SIM Suspension</StyledTextHeader>

                <span style={{ display: 'flex' }}>
                  <span
                    style={
                      simSuspension
                        ? { width: '26px' }
                        : {
                            color: ptColors.device.deviceStatusGrey,
                            width: '26px'
                          }
                    }
                  >
                    {simSuspension ? 'ON' : 'OFF'}
                  </span>
                  <button
                    type='button'
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      width: 'min-content',
                      cursor:
                        deviceDetailsData?.data?.sim_expiry_dt &&
                        new Date() >
                          new Date(
                            new Date(deviceDetailsData?.data?.sim_expiry_dt)
                          )
                          ? 'pointer'
                          : 'default'
                    }}
                    onClick={() => {
                      setModalOpen(true)
                    }}
                  >
                    <ToggleSwitch
                      cursor='pointer'
                      switchStatus={simSuspension}
                    />
                  </button>
                </span>
                <span
                  style={{
                    color: '#B9B9B9',
                    fontSize: '12px',
                    marginTop: '-5px',
                    display: 'inline-block',
                    height: '19px'
                  }}
                >
                  {deviceDetailsData?.data?.suspension_dt
                    ? format(
                        new Date(deviceDetailsData?.data?.suspension_dt),
                        'MMM d, yyyy  HH:mm'
                      )
                    : ''}
                </span>
                <span
                  style={{
                    fontSize: '10px',
                    color: ptColors.device.deviceStatusGrey,
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '14.5px',
                    marginTop: '4px'
                  }}
                >
                  <span>
                    Suspension can only be applied to Pocketalk S2 Series.
                  </span>
                  <span>
                    This is for suspending SIM usage and does not apply under
                    WiFi conditions.
                  </span>
                </span>
              </StyledTextContainer>
            )
          )}
        </StyledCard>
      </div>
    </PageTemplate>
  )
}

export default DeviceDetails
