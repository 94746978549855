import SimpleBarReact from 'simplebar-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton, Typography, Box, Button } from '@mui/material'
import THeader from '../Table/THeader'
import TBody from '../_Table/TBody'
import TRow from '../_Table/TRow'
import TCell from '../_Table/TCell'
import { IColumnProps } from '../../../types/table'

import Table from '../../Templates/_Table'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { useStyles } from '../../../themes'
import Pagination from '../Pagination/Pagination'
import usePagination from '../../../hooks/usePagination'

import { ReactComponent as ShowMoreIcon } from '../../../images/icon/show-more-down.svg'
import { ReactComponent as ShowUpIcon } from '../../../images/icon/show-more-up.svg'
import { ReactComponent as CrossWhite } from '../../../images/icon/cross-white.svg'
import {
  TABLE_ROW_NUMBER_PER_PAGE,
  csvDataHeader,
  Product_Types,
  modeEnums,
  overwriteSimEnums
} from '../../../constants/constants'
import { arrayToObject, getFormattedeSIMValidDuration } from '../../../utils/utility'

import ShippingDataModalTableStyle from './style'
// import { useDeviceImportStyles } from '../DeviceImport/style'
import { ReactComponent as ErrorSign } from '../../../images/icon/error-sign-manage-groups.svg'
import CustomTooltip from '../../Atoms/Tooltip'
import { ProductTypes } from '../../../types/shipping-data'
import {
  SalesRegion,
  useLazyRegionListQuery
} from '../../../state/features/serverRegion/serverRegionSlice'
import Loader from '../../Atoms/Loader/Loader'

interface IMEIError {
  param: string
  message: string[]
}
interface Props {
  newCsvData: string[][]
  errors: IMEIError[]
}

const columnProps: IColumnProps = [
  {
    key: 'Corp Id',
    name: 'Corporate ID',
    colWidth: 120,
    colMaxWidth: 150,
    isSort: true
  },
  {
    key: 'Corp Name',
    name: 'Corporate Name',
    colWidth: 100,
    colMaxWidth: 140,
    isSort: true
  },
  {
    key: 'Sales Region',
    name: 'Sales Region',
    colWidth: 70,
    colMaxWidth: 100,
    isSort: true
  },
  {
    key: 'eSIM Valid Duration',
    name: 'eSIM Valid Duration',
    colWidth: 100,
    colMaxWidth: 100,
    isSort: true
  },
  {
    key: 'IMEI1',
    name: 'IMEI1',
    colWidth: 80,
    colMaxWidth: 120,
    isSort: true
  },
  {
    key: 'IMEI2',
    name: 'IMEI2',
    colWidth: 80,
    colMaxWidth: 120,
    isSort: true
  },
  {
    key: 'Product Type',
    name: 'Device Model',
    colWidth: 100,
    colMaxWidth: 130,
    isSort: true
  },
  {
    key: 'Mode',
    name: 'Mode',
    colWidth: 100,
    colMaxWidth: 130,
    isSort: true
  },
  {
    key: 'Overwrite Sim',
    name: 'Overwrite SIM',
    colWidth: 210,
    colMaxWidth: 210,
    isSort: true
  }
]

interface DeviceInfo {
  'Corp Id': string
  'Corp Name': string
  'Sales Region': string
  'eSIM Valid Duration': string
  IMEI1: string
  IMEI2: string
  'Product Type': string
  Mode: string
  'Overwrite Sim': string
  [key: string]: string
}

const ShippingDataModalTable: React.FC<Props> = ({ newCsvData, errors }) => {
  const [isImportErrors, setIsImportErrors] = useState<boolean>(false)
  const [isShowMore, setIsShowMore] = useState<boolean>(false)
  const [isLargeText, setIsLargeText] = useState<boolean>(false)
  // const errorMessageDisplay = isShowMore ? errors : errors.slice(0, 3)
  const [errorMessageDisplay, setErrorMessageDisplay] = useState<string[]>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [viewData, setViewData] = useState<DeviceInfo[]>([])
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })
  const [deviceSelsRegionList, setDeviceSelsRegionList] = useState<
    SalesRegion[]
  >([])

  const [
    triggerRegionList,
    { data: saleRegionList, isLoading: isRegionListLoading }
  ] = useLazyRegionListQuery()

  useEffect(() => {
    void triggerRegionList()
  }, [triggerRegionList])

  useEffect(() => {
    if (saleRegionList?.details)
      setDeviceSelsRegionList(saleRegionList?.details)
  }, [saleRegionList])

  useEffect(() => {
    if (isRegionListLoading) setTableLoading(true)
    else setTableLoading(false)
  }, [isRegionListLoading])

  const {
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage
  } = usePagination(100, 0, newCsvData.length)

  const newCsvDataList = {
    data: {
      newData: arrayToObject(newCsvData),
      total_newData: newCsvData.length
    }
  }

  const classes = useStyles()
  const classes2 = ShippingDataModalTableStyle()

  const { t } = useTranslation()

  useEffect(() => {
    const errorData: string[] = []

    errors?.forEach((item: { param: string; message: string[] | string }) => {
      if (typeof item?.message === 'string') {
        if (item?.message.length > 180 && !isLargeText) {
          setIsLargeText(true)
        }
        errorData.push(`${item?.param}: ${item?.message}`)
      } else if (Array.isArray(item?.message)) {
        item?.message.forEach((message) => {
          if (message?.length > 180 && !isLargeText) {
            setIsLargeText(true)
          }
          errorData.push(`${item?.param}: ${message}`)
        })
      }
    })

    setIsImportErrors(errorData.length > 0)
    setErrorMessageDisplay(errorData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCsvData, errors])

  const handleSort = (key: string) => {
    let direction: DIRECTION_TYPE = DIRECTION_TYPE.ascending

    if (
      sortConfig.key === key &&
      sortConfig.direction === DIRECTION_TYPE.ascending
    ) {
      direction = DIRECTION_TYPE.descending
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === DIRECTION_TYPE.descending
    ) {
      direction = DIRECTION_TYPE.none
    }

    setSortConfig({ key, direction })
    setCurrentPage(0)
  }

  useEffect(() => {
    if (sortConfig?.direction === DIRECTION_TYPE.none) {
      setViewData(
        newCsvDataList?.data.newData?.slice(
          currentPage * rowsPerPage,
          currentPage * rowsPerPage + rowsPerPage
        )
      )
    } else {
      setViewData(
        newCsvDataList.data.newData
          .slice()
          .sort((a: DeviceInfo, b: DeviceInfo) => {
            if (sortConfig?.direction === DIRECTION_TYPE.none) return 0
            if (sortConfig?.direction === DIRECTION_TYPE.ascending) {
              return a[sortConfig?.key]?.localeCompare(b[sortConfig?.key])
            }

            return b[sortConfig?.key]?.localeCompare(a[sortConfig?.key])
          })
          ?.slice(
            currentPage * rowsPerPage,
            currentPage * rowsPerPage + rowsPerPage
          )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig, currentPage, rowsPerPage, newCsvData])

  const calculatePageDataIndexTo = (
    page: number,
    PerPage: number,
    totalData: number
  ) => {
    const endIndex = (page + 1) * PerPage

    return endIndex > totalData ? totalData : endIndex
  }

  const generateToolTipContent = (
    rowData: DeviceInfo,
    key: string,
    ProductTyp: ProductTypes,
    deviceSelsRegion: SalesRegion[]
  ) => {
    if (rowData[key] === '') {
      return '-'
    }


    if (key === 'Sales Region') {
      return (
        deviceSelsRegion?.find(
          (item: SalesRegion) => item?.salesRegionId === Number(rowData[key])
        )?.salesRegion || '-'
      )
    }


    if (key === 'Product Type') {
      return ProductTyp[rowData[key]] || ''
    }

    if (key === 'Mode') {
      const value = Number(rowData[key])
      
      return modeEnums[value] || '-'
    }


    if (key === 'eSIM Valid Duration') {
      const value = Number(rowData[key])

      return getFormattedeSIMValidDuration(value, false)
      
      
    }

    if (key === 'Overwrite Sim') {
      const value = Number(rowData[key])

      return overwriteSimEnums[value] || '-'
    }

    return rowData[key] || '-'
  }

  return (
    <Box
      sx={{
        width: '1476px',
        '@media screen and (max-width: 1600px)': {
          maxWidth: '1100px'
        },
        '@media screen and (max-width: 1200px)': {
          maxWidth: '100%'
        }
      }}
    >
      <Box
        className={classes2.errorDialogBlock}
        style={{
          display: `${isImportErrors ? 'block' : 'none'}`,
          height: `${isShowMore ? '103%' : ''}`,
          width: '100%',
          zIndex: 5
        }}
      >
        <Box className={`${classes2.errorContentBlock}`}>
          <Box className={classes2.errorTitleBlock}>
            <ErrorSign />
            <h3 className={classes2.errorTitle}>
              Error: The uploaded file has some troubles.
            </h3>
            <IconButton
              aria-label='close'
              onClick={() => {
                setIsShowMore(false)
                setIsImportErrors(false)
              }}
              className={classes2.iconBtn}
            >
              <CrossWhite />
            </IconButton>
          </Box>

          {errorMessageDisplay.length > 0 && (
            <SimpleBarReact className={classes2.errorBlock}>
              {!isShowMore
                ? errorMessageDisplay?.slice(0, 3)?.map((item: string) => (
                    <Box key={item} className={classes2.errorItem}>
                      <Typography className={classes2.errorLine}>
                        {item && item?.length > 180
                          ? `${item.substring(0, 180)}...`
                          : item}
                      </Typography>
                    </Box>
                  ))
                : errorMessageDisplay?.map((item: string) => (
                    <Box key={item} className={classes2.errorItem}>
                      <Typography className={classes2.errorLine}>
                        {item}
                      </Typography>
                    </Box>
                  ))}
            </SimpleBarReact>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '22px',
              marginRight: '32px'
            }}
          >
            {(errorMessageDisplay?.length > 3 || isLargeText) &&
              !isShowMore && (
                <Button
                  type='button'
                  className={classes2.showMoreBlock}
                  onClick={() => setIsShowMore(!isShowMore)}
                >
                  <Typography className={classes2.showMoreText}>
                    Show More
                  </Typography>
                  <ShowMoreIcon />
                </Button>
              )}

            {isShowMore && (
              <Button
                type='button'
                className={classes2.showMoreBlock}
                onClick={() => setIsShowMore(!isShowMore)}
              >
                <Typography className={classes2.showMoreText}>
                  Show Less
                </Typography>
                <ShowUpIcon />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <>
        <Box
          style={{
            marginTop: '31px'
          }}
        >
          <SimpleBarReact>
            <Table tableWidth='1474px'>
              <THeader
                clickSortButton={handleSort}
                sortConfig={sortConfig}
                columnProperties={columnProps}
              />

              <TBody isSuccess columnProperties={columnProps} adjustHeight>
                {!tableLoading
                  ? viewData?.map((row: DeviceInfo, index: number) => (
                      // eslint-disable-next-line
                      <TRow key={index} index={index}>
                        {csvDataHeader?.map((key) => {
                          // Iterate over visibleColumns array
                          const column = columnProps.find(
                            (col) => col.key === key
                          )
                          if (column) {
                            return (
                              <TCell
                                colMaxWidth={column.colMaxWidth}
                                colWidth={column.colWidth}
                                isReloading={false}
                                key={key}
                              >
                                <span
                                  style={{
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {/* eslint-disable  */}
                                  <CustomTooltip
                                    toolTipTitle={generateToolTipContent(
                                      row,
                                      key,
                                      Product_Types,
                                      deviceSelsRegionList
                                    )}
                                    maxWidth={column.colMaxWidth}
                                  />
                                </span>
                              </TCell>
                            )
                          }

                          return null
                        })}
                      </TRow>
                    ))
                  : [1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                      <TRow key={i} index={i}></TRow>
                    ))}
              </TBody>
            </Table>
          </SimpleBarReact>
        </Box>
        <div className={classes.dataPagination}>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(
              (newCsvDataList?.data?.total_newData ?? 0) / rowsPerPage
            )}
            clickPrevPageButton={onClickPrevPage}
            clickNextPageButton={onClickNextPage}
            rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
            currentRowNumberPerPage={rowsPerPage}
            changeRowNumber={onChangeRowNumber}
            containFirstAndLastButton
            clickFirstPageButton={onClickFirstPage}
            clickLastPageButton={onClickLastPage}
            pageDataIndexFrom={currentPage * rowsPerPage + 1}
            pageDataIndexTo={calculatePageDataIndexTo(
              currentPage,
              rowsPerPage,
              newCsvDataList?.data?.total_newData ?? 0
            )}
            totalDataRows={newCsvDataList?.data?.total_newData}
          />
        </div>
      </>
      <Typography
        sx={{
          textAlign: 'right',
          marginTop: '20px',
          fontSize: '17px',
          fontWeight: 'bold',
          color: '#0B0B0B'
        }}
      >
        {t('shippingDataImport.confirmationQuery')}
      </Typography>

      {tableLoading && (
        <Box
          sx={{
            width: '40px',
            height: '40px',
            position: 'absolute',
            top: '45%',
            left: '50%',
            zIndex: '1'
          }}
        >
          <Loader colorCode='#939598' />
        </Box>
      )}
    </Box>
  )
}

export default ShippingDataModalTable
