import {
  IExportCsvSfdcListResponse,
  IExportCsvSfdcSearchParamType
} from '../../../types/exportcsvsfdc'
import apiSlice from '../../api/apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['ExportCsvSfdcList']
})

export const exportCsvSfdcSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    exportCsvSfdcList: builder.query<
      IExportCsvSfdcListResponse,
      IExportCsvSfdcSearchParamType
    >({
      query: ({ currentPageNumber, rowLimitPerPage }) => ({
        url: `/private/export-csv-sfdc-list?currentPageNumber=${
          currentPageNumber as number
        }&rowLimitPerPage=${rowLimitPerPage as number}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ['ExportCsvSfdcList']
    })
  })
})

export const { useLazyExportCsvSfdcListQuery } = exportCsvSfdcSlice
