import { useState } from 'react'
import { csvFileContent } from '../utils/utility'

const useCsvValidator = () => {
  const [fileInfo, setFileInfo] = useState<File | null>(null)
  const [csvFileData, setCsvFileData] = useState<string[][]>([])
  const [errors, setErrors] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const readCsvDataFromFile = async (file: File): Promise<string[][]> => {
    try {
      const { csvFileContentConverted: rows } = await csvFileContent(file);


      if (rows[rows.length - 1]?.join('') === '') {
        rows.pop()
      }

      if (rows.length === 0) {
        throw new Error('CSV file is empty')
      }

      return rows
    } catch (error) {
      throw new Error('Error reading CSV file')
    }
  }

  const handleUploadCsvFile = async (file: File | null) => {
    setLoading(true)
    setErrors([])
    if (!file) {
      setErrors(['No file selected'])
      setLoading(false)

      return
    }
    try {
      setFileInfo(file)
      const rows = await readCsvDataFromFile(file)

      if (rows.length > 0) {
        setCsvFileData(rows)
      } else {
        throw new Error('Error handling uploaded CSV file')
      }
    } catch (error) {
      setErrors(['Error handling uploaded CSV file'])
    } finally {
      setLoading(false)
    }
  }

  const reset = () => {
    setFileInfo(null)
    setCsvFileData([])
    setErrors([])
    setLoading(false)
  }

  return { handleUploadCsvFile, reset, fileInfo, csvFileData, errors, loading }
}

export default useCsvValidator
