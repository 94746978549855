import { TResponse } from '../../../types/notification'
import apiSlice from '../../api/apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: []
})

interface IURLDeviceTypeList {
  total_device_url_type_Count: number
  device_url_type_list: {
    id: number
    url_type: string
  }[]
}

export const urlTypeSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    urlTypeList: builder.query<TResponse<IURLDeviceTypeList>, void>({
      query: () => ({
        url: `/private/device-url-type_list`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    })
  })
})

export const { useUrlTypeListQuery } = urlTypeSlice
