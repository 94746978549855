/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-regex-literals */
interface ByteOrderMark {
  encoding: string;
  regex: RegExp;
}

const byteOrderMarks: ByteOrderMark[] = [
  {
    encoding: "UTF-EBCDIC",
    regex: new RegExp("221 115 102 115"),
  },
  {
    encoding: "GB-18030",
    regex: new RegExp("132 49 149 51"),
  },
  {
    encoding: "UTF-32LE",
    regex: new RegExp("255 254 0 0"),
  },
  {
    encoding: "UTF-32BE",
    regex: new RegExp("0 0 254 255"),
  },
  {
    encoding: "UTF-8",
    regex: new RegExp("239 187 191"),
  },
  {
    encoding: "UTF-7",
    regex: new RegExp("43 47 118"),
  },
  {
    encoding: "UTF-1",
    regex: new RegExp("247 100 76"),
  },
  {
    encoding: "SCSU",
    regex: new RegExp("14 254 255"),
  },
  {
    encoding: "BOCU-1",
    regex: new RegExp("251 238 40"),
  },
  {
    encoding: "UTF-16BE",
    regex: new RegExp("254 255"),
  },
  {
    encoding: "UTF-16LE",
    regex: new RegExp("255 254"),
  },
];

const checkUTF = (content: string): boolean => {
  for (let b = 0; b < content.length; b+=1) {
    // If � is encountered it's definitely not utf8!
    if (content[b] === "�") {
      return false;
    }
  }

  return true;
};

const checkByteOrderMark = (uInt8Start: string): string | null => {
  for (const element of byteOrderMarks) {
    if (element.regex.test(uInt8Start)) 
      
      return `${element.encoding  }-with-BOM`;
  }

  return null;
};

interface FileInfo {
  encoding: string | null;
}

const readAndProcessFile = (file: File): Promise<string | null> => new Promise((resolve, reject) => {
    // Initialize fileInfo
    const fileInfo: FileInfo = {
      encoding: null,
    };

    // Check the byte order mark!
    const byteOrderMarkBuffer = new FileReader();

    byteOrderMarkBuffer.onload = () => {
      const uInt8String = Array.from(
        new Uint8Array(byteOrderMarkBuffer.result as ArrayBuffer)
      )
        ?.slice(0, 4)
        ?.join(" ");
      const byteOrderMark = checkByteOrderMark(uInt8String);

      if (byteOrderMark) {
        fileInfo.encoding = byteOrderMark;
        const byteOrderMarkReader = new FileReader();
        byteOrderMarkReader.onload = () => {
          // Resolve with the detected encoding
          resolve(fileInfo.encoding);
        };

        byteOrderMarkReader.onerror = (err) => {
          reject(err);
        };

        byteOrderMarkReader.readAsText(file, fileInfo.encoding);
      } else {
        // Read with UTF-8 first, then with ISO-8859-1
        const utfReader = new FileReader();
        utfReader.onload = () => {
          const utfContent = utfReader.result as string;
          const utf8 = checkUTF(utfContent);
          if (utf8) {
            fileInfo.encoding = "UTF-8";
          }

          if (utf8) {
            // Resolve with the detected encoding
            resolve(fileInfo.encoding);
          } else {
            const isoReader = new FileReader();

            isoReader.onload = () => {
              // Resolve with ISO-8859-1 if UTF-8 decoding fails
              resolve("ISO-8859-1");
            };
            isoReader.readAsText(file, "ISO-8859-1");
          }
        };

        utfReader.onerror = (err) => {
          reject(err);
        };
        utfReader.readAsText(file, "UTF-8");
      }
    };

    byteOrderMarkBuffer.onerror = (err) => {
      reject(err);
    };

    byteOrderMarkBuffer.readAsArrayBuffer(file);
  });

export default readAndProcessFile;
