/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react'
import { toast } from 'react-toastify'
import { PAGE_PATH_NAME } from '../../constants/constants'

export interface ICustomError{
  data:{
    "success": boolean,
    "status": number,
    "result_code": string,
    "message": string
  }
}


const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_DOMAIN ?? 'http://localhost:3000'}/${
    process.env.REACT_APP_API_REGION ?? 'iotconsoleapi-global-bo'
  }/api`,
  prepareHeaders: (headers, { getState }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const token = getState().iotAuthInfo.accessToken
    if (token) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      headers.set('Authorization', `Bearer ${token}`)
    }

    return headers
  }
})

const baseQueryWithInternet: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  const online = window.navigator.onLine
  if (!online) {
    toast.error('No internet available.')
  }

  return result
}

const apiSlice = createApi({
  reducerPath: 'api',

  baseQuery: async (args, api, extraOptions) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = await baseQueryWithInternet(args, api, extraOptions)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (result?.error?.data?.result_code === 'ERR_INVALID_TOKEN_JWT') {
      localStorage.clear()
      window.location.href = PAGE_PATH_NAME.LOGIN
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result
  },
  tagTypes: [],
  endpoints: () => ({})
})

export type apiSliceType = typeof apiSlice
export default apiSlice
