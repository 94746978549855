import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from '../../../../themes'
import { ITableRowProps } from '../../../../types/_table'

const TRow: React.FC<ITableRowProps> = ({ index, hyperlink, type = 'single-line', children, onClick }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div 
      
      tabIndex={Number(index)}
      role='row'
      onKeyPress={()=>{
        //
      }}
      onClick={() => {
        if (hyperlink && hyperlink.valid && hyperlink.state) {
          navigate(`${hyperlink.path}`, { state: { [hyperlink.state.key]: hyperlink.state.value } })
        }
        if(onClick){
          onClick()
        }
      }}
      className={`${classes.tableDataRow} ${hyperlink?.valid ? classes.cursorPointer : classes.noCursorPointer
        }  
      ${type === 'single-line'
          ? classes.tableDataRow
          : classes.tableDataRow_multiline
        }`}
    >
      {children}
    </div>
  )
}

export default TRow
