/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import { Stack, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { IColumnProp, IColumnProps } from '../../../types/table'
import PageTemplate from '../PageTemplate'
import DeviceImportDialog from '../../Modecules/DeviceImport'
import SearchField from '../../Atoms/SearchInput'
import Button from '../../Atoms/Buttons'
import { ReactComponent as ImportIcon } from '../../../images/icon/import-icon.svg'
import { TABLE_ROW_NUMBER_PER_PAGE } from '../../../constants/constants'
import { useStyles } from '../../../themes'
import usePagination from '../../../hooks/usePagination'
import { useLazyDeviceImportListQuery } from '../../../state/features/deviceimport/deviceImportSlice'
import Table from '../../Templates/Table'
import { ReactComponent as Corporation } from '../../../images/icon/no-device.svg'
import Pagination from '../../Modecules/Pagination/Pagination'
import Loader from '../../Atoms/Loader/Loader'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import CustomTooltip from '../../Atoms/Tooltip'
import StatusButton from '../../Atoms/StatusButton'
import { convertBlockUnderscoreTextIntoCamelCaseWithSpace } from '../../../utils/utility'
import { ReactComponent as DownloadIcon } from '../../../images/icon/download-blue.svg'
import { ReactComponent as DownloadIconDisabled } from '../../../images/icon/download-gray.svg'
import { IDeviceImportResponse } from '../../../types/deviceimport'
import { UserStateType } from '../../../types/users'
import RegionFilter from '../../Modecules/RegionFilter'
import { serverRegionType } from '../../../state/features/serverRegion/serverRegionSlice'
import { ptColors } from '../../../themes/settings/_colors'

const DeviceImportList = (): JSX.Element => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  // const [isDeviceDataSuccess, setIsDeviceDataSuccess] = useState<boolean>(false)
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })
  const [selectedServerRegion, setSelectedServerRegion] = useState<
    serverRegionType[]
  >([])
  const [selectedServerRegionId, setSelectedServerRegionId] = useState<
    number[]
  >([])

  const { t } = useTranslation()

  const handleClickOpen = () => {
    setOpen(!open)
  }

  const [filterQuery, setFilterQuery] = useState({
    deviceImportHistorySearchParam: '',
    sortDirection: 'DESC',
    colName: 'createdDt',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0,
    serverRegionIdList: selectedServerRegionId
  })

  const user = useSelector((state: UserStateType) => state?.iotAuthInfo)

  const [
    trigger,
    {
      data: deviceImportList,
      isSuccess: deviceImportListIsSuccess,
      isFetching: deviceImportListIsReloading,
      isError: deviceImportListError,
      error: deviceImportListErrorData
    }
  ] = useLazyDeviceImportListQuery()

  const {
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    deviceImportList
      ? deviceImportList?.data?.total_device_import_history_count
      : 0
  )

  useEffect(() => {
    toast.error(deviceImportListErrorData?.data?.message)
    if (deviceImportListErrorData?.data?.error) {
      toast.error(deviceImportListErrorData?.data?.error)
    }
  }, [deviceImportListError])

  useEffect(() => {
    const selectedIds = selectedServerRegion?.map(
      (region: serverRegionType) => region?.id
    )

    setSelectedServerRegionId(selectedIds)

    setFilterQuery((prev) => ({
      ...prev,
      serverRegionIdList: selectedIds
    }))
  }, [selectedServerRegion])

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setCurrentPage(0)
  }, [filterQuery.deviceImportHistorySearchParam, setCurrentPage])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  const sortFieldsNameReplace = (substr: string) => {
    if (substr === 'corp_id') {
      return 'corpId'
    }
    if (substr === 'company') {
      return 'corpName'
    }
    if (substr === 'order_id') {
      return 'orderId'
    }
    if (substr === 'number_of_devices') {
      return 'numberOfDevices'
    }
    if (substr === 'status_code') {
      return 'statusCode'
    }
    if (substr === 'server_region_name') {
      return 'serverRegionName'
    }
    if (substr === 'importer_name') {
      return 'importerName'
    }
    if (substr === 'created_dt') {
      return 'createdDt'
    }

    return ''
  }

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection: sortConfigUpdate.direction,
      colName: sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }

  const columnProps: IColumnProps = [
    {
      key: 'corp_id',
      name: 'Corporate ID',
      colWidth: 187,
      colMaxWidth: 187,
      isSort: true
    },
    {
      key: 'company',
      name: 'Corporate Name',
      colWidth: 138,
      colMaxWidth: 138,
      isSort: true
    },
    {
      key: 'order_id',
      name: 'Order No',
      colWidth: 95,
      colMaxWidth: 95,
      isSort: true
    },
    {
      key: 'number_of_devices',
      name: 'No. of Devices',
      colWidth: 105,
      colMaxWidth: 105,
      isSort: true
    },
    {
      key: 'status_code',
      name: 'Status',
      colWidth: 105,
      colMaxWidth: 105,
      isSort: true
    },
    {
      key: 'created_dt',
      name: 'Import Date',
      colWidth: 125,
      colMaxWidth: 125,
      isSort: true
    },
    {
      key: 'server_region_name',
      name: 'Server Region',
      colWidth: 108,
      colMaxWidth: 108,
      isSort: true
    },
    {
      key: 'importer_name',
      name: 'Importer',
      colWidth: 100,
      colMaxWidth: 100,
      isSort: true
    },
    {
      key: 'download_file',
      name: 'Download',
      colWidth: 105,
      colMaxWidth: 105
    }
  ]

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'GRAY':
        return ptColors.deviceImport.statusGrey
      case 'GREEN':
        return ptColors.deviceImport.statusGreen
      default:
        return ptColors.white
    }
  }

  const handleDownload = async (file_name: string) => {
    await fetch(
      `${process.env.REACT_APP_API_DOMAIN ?? 'http://localhost:3000'}/${
        process.env.REACT_APP_API_REGION
      }/api/private/downloadcsvfrombackup?fileName=${encodeURIComponent(
        file_name
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      }
    )
      .then((response: any) => {
        if (response.status === 200) {
          return response.blob()
        }
        throw Error()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file_name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        toast.success('File downloaded successfully')
      })
      .catch((error) => {
        toast.error('Unable to download this file')
      })
  }

  const cellDataGenerator = (cell: IColumnProp, row: IDeviceImportResponse) => {
    switch (cell.key) {
      case 'corp_id': {
        if (row[cell.key]) {
          return row[cell.key]
        }

        return '-'
      }

      case 'company': {
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          )
        }

        return '-'
      }

      case 'order_id': {
        if (row[cell.key]) {
          return row[cell.key]
        }

        return '-'
      }

      case 'number_of_devices': {
        if (row[cell.key]) {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                paddingRight: '15px'
              }}
            >
              {row[cell.key]}
            </span>
          )
        }

        return '-'
      }

      case 'status_code': {
        if (row[cell.key]) {
          return (
            <StatusButton
              // bgColor={row.color_code_name === "BLUE" ? "#9AA5B1" : "#8AB165"}
              bgColor={getStatusColor(row.color_code_name)}
              borderColor={
                row.color_code_name === 'GREENBORDER'
                  ? ptColors.deviceImport.statusGreen
                  : null
              }
              text={convertBlockUnderscoreTextIntoCamelCaseWithSpace(
                row.status_code ? row.status_code : ''
              )}
            />
          )
        }

        return '-'
      }

      case 'created_dt': {
        if (row?.created_dt) {
          return format(new Date(row.created_dt), 'MMM d, yyyy  HH:mm')
        }

        return '-'
      }

      case 'server_region_name': {
        if (row?.server_region_name) {
          return row[cell.key]
        }

        return '-'
      }

      case 'importer_name': {
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          )
        }

        return '-'
      }

      case 'download_file': {
        if (row[cell.key]) {
          return (
            <span
              onClick={() => {
                void handleDownload(row.download_file)
              }}
              style={{
                fontSize: '12px',
                color: ptColors.deviceImport.downloadButton,
                display: 'flex',
                gap: '9px',
                alignItems: 'center',
                cursor: 'pointer',
                textDecoration: 'none'
              }}
            >  
              Download
            </span>
          )
        }

        return (
          <span
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            style={{
              fontSize: '12px',
              color: 'gray',
              display: 'flex',
              gap: '9px',
              alignItems: 'center',
              cursor: 'default',
              textDecoration: 'none'
            }}
          >
            <DownloadIconDisabled />
            Download
          </span>
        )
      }

      default:
        break
    }

    return ''
  }

  const getPageDataIndexTo = () => {
    if (deviceImportList) {
      if (
        (currentPage + 1) * rowsPerPage >
        deviceImportList?.data?.total_device_import_history_count
      ) {
        return deviceImportList?.data?.total_device_import_history_count
      }

      return (currentPage + 1) * rowsPerPage
    }

    return 0
  }

  return (
    <PageTemplate>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <div className={classes.searchBlockWithFilter}>
          <SearchField
            clickFilterSearch={(val, isChange) => {
              if (isChange) {
                setFilterQuery((prevState) => ({
                  ...prevState,
                  deviceImportHistorySearchParam: val
                }))
              }
            }}
            placeholder='Search by keywords'
          />
          <RegionFilter setSelectedServerRegion={setSelectedServerRegion} />
        </div>
        <Box>
          <DeviceImportDialog onClose={handleClickOpen} open={open} />
          <Button
            className={classes.importBtn}
            color='primary'
            onClick={handleClickOpen}
          >
            <ImportIcon />
            {t('deviceImport.newImport')}
          </Button>
        </Box>
      </Stack>
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('deviceImport.deviceHeaderTitle')}`} (
            {deviceImportList?.data?.total_device_import_history_count ?? 0})
          </Typography>
        </div>

        {deviceImportList?.data?.total_device_import_history_count === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <Corporation />
            <p className={classes.noFountText}>
              {t('deviceImport.noDeviceFound')}
            </p>
            <p className={classes.noAvailableText}>
              {t('deviceImport.currentlyNoDevices')}
            </p>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table
                tableWidth='1496px'
                dataRows={
                  deviceImportList
                    ? deviceImportList?.data?.device_import_history_list
                    : []
                }
                clickSortButton={onClickSortButton}
                sortConfig={sortConfig}
                isSuccess={deviceImportListIsSuccess}
                isReloading={deviceImportListIsReloading}
                skeletonProps={columnProps}
                columnProps={columnProps}
                cellDataGenerator={cellDataGenerator}
              />
            </SimpleBar>
            {deviceImportListIsReloading && deviceImportListIsSuccess ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top: '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode={ptColors.deviceImport.loaderGrey} />
              </Box>
            ) : null}
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  (deviceImportList
                    ? deviceImportList?.data?.total_device_import_history_count
                    : 0) / rowsPerPage
                )}
                clickPrevPageButton={onClickPrevPage}
                clickNextPageButton={onClickNextPage}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={getPageDataIndexTo()}
                totalDataRows={
                  deviceImportList
                    ? deviceImportList?.data?.total_device_import_history_count
                    : 0
                }
              />
            </div>
          </Box>
        )}
      </Box>
    </PageTemplate>
  )
}

export default DeviceImportList
