/* eslint-disable react/no-array-index-key */
import { Skeleton, Box } from '@mui/material'
import { useStyles } from '../../../themes'

interface ISkeletonProp {
  colWidth: number
  [key: string]: unknown
}

interface ISkeletonProps {
  skeletonProps: ISkeletonProp[]
  gap?: string
}

const LoadingSkeletonEula: React.FC<ISkeletonProps> = ({
  skeletonProps,
  gap
}): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      {Array.from({ length: 2 }, (_, k) => (
        <Box
          key={k}
          style={{ gap: gap ? gap : '42px' }}
          className={classes.tableDataRowSkeletonEula}
        >
          {skeletonProps.map((element, index) => (
            <Box key={index} className={classes.col} width={element.colWidth}>
              <Skeleton variant='rectangular' height={20} />
            </Box>
          ))}
        </Box>
      ))}
    </>
  )
}

export default LoadingSkeletonEula
