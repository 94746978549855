/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import SimpleBarReact from 'simplebar-react'
import { Box } from '@mui/material'
import { useStyles } from '../../../../themes'
import { ITableRowProps } from '../../../../types/table'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const TBody: React.FC<ITableRowProps> = ({
  gap,
  pageSrc,
  type = 'single-line',
  rows,
  isReloading,
  columnProperties,
  cellDataGenerator,
  hyperlink = { valid: false }
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Box>
      <SimpleBarReact
        className={pageSrc === 'eula' ? '' : classes.tableBodySimpleBar}
      >
        <div className={classes.tableRowContainer}>
          {rows?.map((row: any, index: number) => (
            <div
              onClick={() => {
                if (hyperlink.valid) {
                  // if()
                  if (row.status === 'SUBMITTED') {
                    // #F6AB00
                    toast.error('Please wait until this notification is posted')
                    return
                  }
                  navigate(`${hyperlink.path}`, {
                    state: { [hyperlink.state]: row[hyperlink.state] }
                  })
                }
              }}
              key={index}
              style={{ gap: gap ? gap : '42px' }}
              className={`${classes.tableDataRow} ${
                hyperlink.valid ? classes.cursorPointer : ''
              }  ${
                type === 'single-line'
                  ? pageSrc === 'eula'
                    ? classes.noHover
                    : classes.tableDataRow
                  : classes.tableDataRow_multiline
              }`}
            >
              {columnProperties.map((cell) => (
                <div
                  key={cell.key}
                  style={{
                    width: `${cell?.colWidth}px`,
                    maxWidth: `${cell?.colMaxWidth}px`,
                    minWidth: `${cell?.colWidth}px`,
                    opacity: isReloading ? '0' : '1',
                    minHeight: '30px',
                    lineHeight: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: "'Noto Sans JP', sans-serif"
                  }}
                >
                  {cellDataGenerator(cell, row, index)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </SimpleBarReact>
    </Box>
  )
}

export default TBody
