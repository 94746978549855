// This file DOES NOT USE any constant object or external file

import { Breadcrumbs, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { useStyles } from '../../themes/index'
import { PAGE_PATH_NAME_TITLE } from '../../constants/constants'

const Breadcrumb: FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const crumbs = location.pathname.split('/').slice(1)

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumb} aria-label='breadcrumb'>
        <Typography
          className={
            // classes.breadcrumbLink
            location.pathname !== '/'
              ? classes.breadcrumbLink
              : classes.breadcrumbActiveLink
          }
          role='presentation'
          onClick={() => {
            navigate(`/`)
          }}
        >
          Home
        </Typography>
        {crumbs?.map((element, i, arr) => {
          if (i >= crumbs.length - 1) {
            return null
          }
          
          return (
            <Typography
              className={classes.breadcrumbLink}
              key={`${element+String(i)}`}
              role='presentation'
              onClick={() => {
                navigate(`/${arr.slice(0, i + 1).join('/')}`)
              }}
            >
              <span
                className={classes.breadcrumbLink}
              >
                {PAGE_PATH_NAME_TITLE[element]}
              </span>
            </Typography>
          )
        })}
        <Typography
          className={classes.breadcrumbActiveLink}
          role='presentation'
        />
      </Breadcrumbs>
      <span className={classes.breadcrumbTitle}>
        {PAGE_PATH_NAME_TITLE[crumbs.slice(crumbs.length - 1)[0]]}
      </span>
    </div>
  )
}

export default Breadcrumb
