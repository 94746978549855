import React from 'react'
import {
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Box
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useStyles } from '../../themes/index'

import { Icon } from '../Atoms/icon'
import { PAGE_PATH_NAME } from '../../constants/constants'
/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
// type Props = {
//   // ドロワーメニューを閉じる
//   closeMainMenu: () => void;
//   // [ログデータ]メニューがクリックされた
//   onClickLogDataMenu: () => void;
//   closeSettingMenu: (value: string) => void;
//   isSettingMenuOpen: boolean;
//   isDrawerMenuOpen: boolean;
//   onClickOpenDrawer: () => void;
// };

/**
 * メインメニューコンポーネント
 *
 * @returns
 */

type CustomProps = {
  handleDrawerOpen: () => void
}

const MainMenu: React.FC<CustomProps> = ({ handleDrawerOpen }): JSX.Element => {
  // 共通スタイル
  const classes = useStyles()
  /*eslint-disable*/
  // const { pathname } = useLocation();
  // const groupName = pathname.split('/')[3];

  const mainMenu = [
    {
      id: 1,
      name: 'Corporations',
      link: PAGE_PATH_NAME.COMPANY_LIST,
      icon: 'corporation'
    },
    {
      id: 2,
      name: 'Devices',
      link: PAGE_PATH_NAME.DEVICES,
      icon: 'device'
    },
    {
      id: 3,
      name: 'Shipping Data Import',
      link: PAGE_PATH_NAME.SHIPPING_DATA_IMPORT,
      icon: 'shipping-data-import'
    },
    {
      id: 4,
      name: 'Device Import',
      link: PAGE_PATH_NAME.DEVICE_IMPORT,
      icon: 'import'
    },
    {
      id: 5,
      name: 'Export CSV for SFDC',
      link: PAGE_PATH_NAME.EXPORT_CSV_TO_SFDC,
      icon: 'import-icon-up'
    },
    {
      id: 6,
      name: 'Notifications',
      link: PAGE_PATH_NAME.NOTIFICATION_MANAGEMENT,
      icon: 'notification'
    },

    {
      id: 7,
      name: 'Device URL Mappings',
      link: PAGE_PATH_NAME.DEVICE_URL_MAPPING_LIST,
      icon: 'device-urlmap'
    },
    {
      id: 8,
      name: 'Region Mappings',
      link: PAGE_PATH_NAME.REGION_MAPPING_LIST,
      icon: 'region-mapping'
    },
    {
      id: 9,
      name: 'User Management',
      link: PAGE_PATH_NAME.USER_MANAGEMENT,
      icon: 'usergroup'
    }
  ]

  return (
    <List className={classes.listItemSidebar}>
      {mainMenu.map((menu, index) =>
        menu.link === '' ? (
          <Box
            className={classes.navButton}
            key={index}
            onClick={handleDrawerOpen}
          >
            <ListItemButton disableRipple>
              <ListItemIcon
                className={`${classes.listItemIcon} ${
                  menu.icon === 'notification' && classes.rotateIcon
                }`}
              >
                <Icon iconName={menu.icon} mask={true} />
              </ListItemIcon>
              <ListItemText primary={menu.name} disableTypography />
            </ListItemButton>
          </Box>
        ) : (
          <NavLink
            to={menu.link}
            className={classes.navLink}
            key={index}
            children={({ isActive }) => {
              return (
                <>
                  <ListItemButton disableRipple>
                    <ListItemIcon
                      className={`${classes.listItemIcon} ${
                        menu.icon === 'notification' && classes.rotateIcon
                      }`}
                    >
                      <Icon iconName={menu.icon} mask={!isActive} />
                    </ListItemIcon>
                    <ListItemText primary={menu.name} disableTypography />
                  </ListItemButton>
                </>
              )
            }}
          />
        )
      )}
    </List>
  )
}

export default MainMenu
