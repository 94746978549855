/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import apiSlice from '../../api/apiSlice'
// eslint-disable-next-line import/no-unresolved
import {
  ICorporationListResponse, corporationSearchParamType
} from '../../../types/corporation'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["corporationsList"],
})

export const corporationsSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    corporationList: builder.query<ICorporationListResponse, corporationSearchParamType>({
      query: ({ searchText = '', currentPageNumber, rowLimitPerPage, sortColName='', sortDirection='', serverRegionIdList}) => ({
          url: `private/corporation-list?corporateListSearchParam=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${sortColName}&sortDirection=${sortDirection}&serverRegionIdList=${serverRegionIdList}`,
          method: 'GET',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json'
          }
          // body: queryParam
        }),
      providesTags: ["corporationsList"],
    }),
    deleteCorporation: builder.mutation<any, any>({
      query: ({ corpId }) => ({
        url: `/private/corporation-delete?corpId=${corpId as string}`,
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ["corporationsList"],
    })
  })
})

export const { useCorporationListQuery, useLazyCorporationListQuery, useDeleteCorporationMutation } =
  corporationsSlice
