/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Box, Button, Typography } from '@mui/material'
import SimpleBar from 'simplebar-react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Table from '../../Templates/_Table'
import THeader from '../../Modecules/Table/THeader'
import usePagination from '../../../hooks/usePagination'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { TABLE_ROW_NUMBER_PER_PAGE } from '../../../constants/constants'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import { IColumnProps } from '../../../types/_table'
import TBody from '../../Modecules/_Table/TBody'
import TRow from '../../Modecules/_Table/TRow'
import TCell from '../../Modecules/_Table/TCell'
import PageTemplate from '../PageTemplate'
import { useStyles } from '../../../themes'
import Loader from '../../Atoms/Loader/Loader'
import Pagination from '../../Modecules/Pagination/Pagination'
import { ReactComponent as Corporation } from '../../../images/icon/no-device.svg'
// import csvToSFDC from '../../../mocks/data/csvToSFDC'

import { useLazyExportCsvSfdcListQuery } from '../../../state/features/exportcsvsfdc/exportCsvSfdcSlice'

import { UserStateType } from '../../../types/users'
import CustomTooltip from '../../Atoms/Tooltip'
import { formattedSize } from '../../../utils/utility'

const ExportCSVtoSFDC = () => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })
  const [downloadIds, setDownloadIds] = useState<number[]>([])

  const user = useSelector((state: UserStateType) => state?.iotAuthInfo)

  const [
    trigger,
    {
      data: csvToSFDC,
      isSuccess: csvToSFDCListIsSuccess,
      isFetching: csvToSFDCListIsReloading,
      isError: csvToSFDCListError,
      error: csvToSFDCListErrorData
    }
  ] = useLazyExportCsvSfdcListQuery()

  useEffect(() => {
    toast.error(csvToSFDCListErrorData?.data?.message)
    if (csvToSFDCListErrorData?.data?.error) {
      toast.error(csvToSFDCListErrorData?.data?.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvToSFDCListError])

  const [filterQuery, setFilterQuery] = useState({
    sortDirection: '',
    colName: '',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0
  })

  const classes = useStyles()
  const { t } = useTranslation()

  const {
    currentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    csvToSFDC ? csvToSFDC?.data?.total_exports : 0
  )

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  const columnProps: IColumnProps = [
    {
      key: 'file_name',
      name: 'File Name',
      colWidth: 130,
      colMaxWidth: 130,
      isSort: false
    },
    {
      key: 'file_creation_date',
      name: 'File Creation Date',
      colWidth: 120,
      colMaxWidth: 120,
      isSort: false
    },
    {
      key: 'file_size',
      name: 'File Size',
      colWidth: 70,
      colMaxWidth: 70,
      isSort: false
    },
    {
      key: 'export',
      name: 'Export',
      colWidth: 65,
      colMaxWidth: 65,
      isSort: false
    }
  ]

  const sortFieldsNameReplace = (substr: string) => {
    if (substr === 'server_name') {
      return 'serverName'
    }
    if (substr === 'server_region') {
      return 'server_region'
    }
    if (substr === 'endpoint_name') {
      return 'destinationInfo'
    }
    if (substr === 'url') {
      return 'schedulerStartDate'
    }

    return ''
  }

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate.key === '' ? '' : sortConfigUpdate.direction,
      colName:
        sortConfigUpdate.direction === DIRECTION_TYPE.none
          ? ''
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }

  const onClickHandleDownload = async ({ file_name = '', id = 0 }) => {
    setDownloadIds((prev) => [...prev, id])
    await fetch(
      `${process.env.REACT_APP_API_DOMAIN ?? 'http://localhost:3000'}/${
        process.env.REACT_APP_API_REGION
      }/api/private/download-export-to-sfdc?fileName=${encodeURIComponent(
        file_name
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      }
    )
      .then((response: any) => {
        if (response?.status === 200) {
          return response?.blob()
        }
        throw Error()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file_name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        toast.success('File downloaded successfully')
        setDownloadIds((prev) => prev.filter((item) => item !== id))
      })
      .catch(() => {
        toast.error('Unable to download this file')
        setDownloadIds((prev) => prev.filter((item) => item !== id))
      })
  }

  return (
    <PageTemplate>
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('exportCSVToSFDC.headerTitle')} `}(
            {csvToSFDC?.data?.total_exports ?? 0})
          </Typography>
        </div>

        {csvToSFDC?.data?.total_exports === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <Corporation />
            <p className={classes.noFountText}>
              {t('deviceUrlMapping.noDataTitle')}
            </p>
            <p className={classes.noAvailableText}>
              {t('deviceUrlMapping.currentlyNoDeviceUrlMapping')}
            </p>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table>
                <THeader
                  clickSortButton={onClickSortButton}
                  columnProperties={columnProps}
                  sortConfig={sortConfig}
                />
                <TBody
                  columnProperties={columnProps}
                  isSuccess={csvToSFDCListIsSuccess}
                >
                  {csvToSFDC?.data?.exports?.map(
                    ({ id, file_name, file_size, file_creation_date }) => (
                      <TRow index={id} key={id}>
                        <TCell
                          colMaxWidth={columnProps[0].colMaxWidth}
                          colWidth={columnProps[0].colWidth}
                          isReloading={false}
                          key='file_name'
                        >
                          <CustomTooltip
                            toolTipTitle={file_name}
                            maxWidth={130}
                          />
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[1].colMaxWidth}
                          colWidth={columnProps[1].colWidth}
                          isReloading={false}
                          key='file_creation_date'
                        >
                          {format(
                            new Date(file_creation_date),
                            'MMM d, yyyy  HH:mm'
                          )}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[2].colMaxWidth}
                          colWidth={columnProps[2].colWidth}
                          isReloading={false}
                          key='file_size'
                          style={{ justifyContent: 'end' }}
                        >
                          {file_size}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[3].colMaxWidth}
                          colWidth={columnProps[3].colWidth}
                          isReloading={false}
                          key='url'
                        >
                          {formattedSize(file_size)?.value > 0 && (
                            <Button
                              disabled={downloadIds.includes(id)}
                              onClick={() => {
                                void onClickHandleDownload({ file_name, id })
                              }}
                              className={classes.downloadButton}
                            >
                              Export
                            </Button>
                          )}
                        </TCell>
                      </TRow>
                    )
                  )}
                </TBody>
              </Table>
            </SimpleBar>
            {csvToSFDCListIsReloading && csvToSFDCListIsSuccess ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top:
                    // eslint-disable-next-line no-nested-ternary
                    csvToSFDC?.data?.total_exports === 1
                      ? '10%'
                      : csvToSFDC?.data?.total_exports === 2
                      ? '25%'
                      : '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode='#939598' />
              </Box>
            ) : null}
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  (csvToSFDC && csvToSFDC.data
                    ? csvToSFDC.data.total_exports ?? 0
                    : 0) / rowsPerPage
                )}
                clickPrevPageButton={() => {
                  onClickPrevPage()
                }}
                clickNextPageButton={() => {
                  onClickNextPage()
                }}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={
                  // eslint-disable-next-line no-nested-ternary
                  (currentPage + 1) * rowsPerPage >
                  (csvToSFDC ? csvToSFDC.data.total_exports : 0)
                    ? csvToSFDC
                      ? csvToSFDC.data.total_exports
                      : 0
                    : (currentPage + 1) * rowsPerPage
                }
                totalDataRows={csvToSFDC ? csvToSFDC.data.total_exports : 0}
              />
            </div>
          </Box>
        )}
      </Box>
    </PageTemplate>
  )
}

export default ExportCSVtoSFDC
