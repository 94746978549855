/* eslint-disable react/require-default-props */
import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Loader from './Loader/Loader'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiPaper-root': {
    width: '460px',
    borderRadius: '10px'
  },
  '& .MuiDialogActions-root': {
    padding: '0 24px 27px 24px'
  }
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
  isCloseIconAsh?: boolean
}

export const useStyles = makeStyles(() => ({
  iconBtn: {
    position: 'absolute',
    padding: 4,
    right: 12,
    top: 12,
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  iconBtnAsh: {
    position: 'absolute',
    padding: 4,
    right: 12,
    top: 12,
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '& svg': {
      fill: '#abb7c5'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    columnGap: '14px'
  },
  body: {
    width: '282px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rowGap: '10px'
  },
  cancelBtn: {
    color: '#232f3e',
    backgroundColor: '#FAFAFA',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #DDDDDD',
    height: '44px',
    padding: '10px 19px',
    textTransform: 'none',
    borderRadius: '4px',
    margin: 0,
    '&:hover': {
      borderColor: '1px solid #D1D1D1',
      color: '#000000'
    },
    '&:focus': {
      outlineColor: '#CCEBF9',
      outlineWidth: '4px',
      outlineStyle: 'solid',
      color: '#000000'
    }
  },
  successBtn: {
    display: 'flex',
    gap: '7px',
    color: '#FFFFFF',
    backgroundColor: '#DD0000',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #DD0000',
    height: '44px',
    padding: '10px 19px',
    textTransform: 'none',
    borderRadius: '4px',
    margin: '0 0 0 7px',
    '&:hover': {
      backgroundColor: '#e97272'
    },
    '&:focus': {
      outlineColor: '#CCEBF9',
      outlineWidth: '5px',
      outlineStyle: 'solid'
    },
    '&:disabled': {
      color: '#FFFFFF',
      backgroundColor: '#DD0000',
      border: '1px solid #DD0000',
      opacity: '0.5'
    }
  },
  abandon: {
    backgroundColor: '#FF6712',
    order: '1px solid #D84D00'
  },
  isModalTop: {
    '& .MuiDialog-container': {
      marginTop: '148px',
      height: 'unset'
    }
  }
}))

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles()
  const { children, onClose, isCloseIconAsh, ...id } = props

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DialogTitle
      sx={{ m: 0, padding: '24px 24px 17px 24px' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...id}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          className={
            isCloseIconAsh ? classes.iconBtnAsh : classes.iconBtn
          }
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
export interface DialogProps {
  open: boolean
  handleClose: () => void
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >
  handleSubmit: () => void
  title: string
  description: string
  cancelText: string
  successText: string
  abandon?: boolean
  isModalTop?: boolean
  isLoading?: boolean
}

const CommonDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  handleSubmit,
  icon: Icon,
  description,
  title,
  cancelText,
  successText,
  abandon,
  isModalTop,
  isLoading = false
}) => {
  const classes = useStyles()

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        className={isModalTop  ? classes.isModalTop : ''}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
          isCloseIconAsh={isModalTop}
        >
          <Box className={classes.title}>
            <Icon />
            <Box className={classes.body}>
              <Typography
                gutterBottom
                fontSize='18px'
                fontWeight='700'
                margin='0'
                lineHeight='26px'
                color='#232f3e'
              >
                {title}
              </Typography>
              <Typography
                gutterBottom
                fontSize='14px'
                fontWeight='300'
                margin='0'
                lineHeight='20px'
                color='#232f3e'
              >
                {description}
              </Typography>
            </Box>
          </Box>
        </BootstrapDialogTitle>

        <DialogActions disableSpacing>
          <Button
            disabled={isLoading}
            variant='outlined'
            autoFocus
            onClick={handleClose}
            disableRipple
            className={classes.cancelBtn}
          >
            {cancelText}
          </Button>
          <Button
            disabled={isLoading}
            variant='text'
            autoFocus
            onClick={handleSubmit}
            disableRipple
            className={`${classes.successBtn} ${
              abandon ? classes.abandon : ''
            }`}
          >
            {isLoading ? <Loader colorCode='#FFFFFF' /> : ''}
            {successText}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default CommonDialog
