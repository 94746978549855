import React from 'react'
import SimpleBarReact from 'simplebar-react'
import { Box } from '@mui/material'
import { useStyles } from '../../../../themes'
import { ITableBody } from '../../../../types/_table'
import LoadingSkeleton from '../../../Atoms/_TableSkeleton'

const TBody: React.FC<ITableBody> = ({
  children,
  isSuccess,
  columnProperties,
  adjustHeight = false,
  customHeight
}) => {
  const classes = useStyles()

  return (
    <div className={classes.dataTableBody}>
      <Box>
        <SimpleBarReact
          style={{ height: customHeight }}
          className={
            adjustHeight
              ? classes.tableBodySimpleBarAdjust
              : classes.tableBodySimpleBar
          }
        >
          <div className={classes.tableRowContainer}>
            {!isSuccess ? (
              <LoadingSkeleton skeletonProps={columnProperties} />
            ) : (
              children
            )}
          </div>
        </SimpleBarReact>
      </Box>
    </div>
  )
}

export default TBody
