/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_URL } from '../../../constants/constants'
// import {
//   IDeviceListResponse,
//   deviceSearchParamType
// } from '../../../types/device'
import {
  IShippingDataDetailsResponse,
  IShippingDataListResponse,
  ItemCodeListResponse,
  ShippingCsvFileUploadResponse,
  shippingDataDetailsParamType,
  shippingDataSearchParamType
} from '../../../types/shipping-data'
import apiSlice from '../../api/apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['ShippingDataList', 'ShippingDataDetailsList']
})

export const shippingSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    shippingDataList: builder.query<
      IShippingDataListResponse,
      shippingDataSearchParamType
    >({
      query: ({
        //   searchText,
        currentPageNumber,
        rowLimitPerPage,
        colName,
        sortDirection
      }: shippingDataSearchParamType) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `/private/shipping-data-list?currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${sortDirection}`, // ?searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${sortDirection}
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),

      providesTags: ['ShippingDataList']
    }),
    shippingDataDetailsList: builder.query<
      IShippingDataDetailsResponse,
      shippingDataDetailsParamType
    >({
      query: ({
        id,
        currentPageNumber,
        rowLimitPerPage,
        sortColName,
        sortDirection
      }: shippingDataDetailsParamType) => ({
        url: `/private/shipping-data-details?fileId=${id}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${sortColName}&sortDirection=${sortDirection}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ['ShippingDataDetailsList']
    }),

    shippingCsvFileUpload: builder.mutation<
    ShippingCsvFileUploadResponse,
      FormData
    >({
      query: (formData) => ({
        url: '/private/shipping-data',
        method: 'POST',
        body:  formData ,
        headers: {
          accept: 'application/json',
        },
        formData: true
      })
    }),

    itemCodeList: builder.query<ItemCodeListResponse, void>({
      query: () => ({
        url: '/private/item-code-list',
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })

    }),

    corporationSync: builder.mutation<any, void>({
      query: () => ({
        url: '/private/corporation-sync-auth0-org',
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }),

    // deviceListSearch: builder.mutation<any, any>({
    //     query: ({
    //       searchText,
    //       currentPageNumber,
    //       rowLimitPerPage,
    //       sortColName,
    //       sortDirection
    //     }: deviceSearchParamType) => ({
    //       // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    //       url: `private/devicelist?searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${sortColName}&sortDirection=${sortDirection}`,
    //       method: 'GET',
    //       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //       headers: {
    //         accept: 'application/json',
    //         'Content-Type': 'application/json'
    //       }
    //       // body: queryParam
    //     })
    //   })
  })
})

export const {
  useShippingDataListQuery,
  useLazyShippingDataListQuery,
  useLazyShippingDataDetailsListQuery,
  useShippingCsvFileUploadMutation,
  useItemCodeListQuery,
  useCorporationSyncMutation
} = shippingSlice
