import React from 'react';
import { Box } from '@mui/material';
import SimpleBarReact from 'simplebar-react';
import { useStyles } from '../../themes/index';
import Header from '../Modecules/Header';
import Sidebar from '../Modecules/Sidebar';
// hooks
// import useLoginInfo from '../../hooks/useLoginInfo';
import Breadcrumb from '../Atoms/Breadcrumb';
import useAuthHook from '../../hooks/useAuth';

type Props = {
  children: React.ReactNode;
};

const PageTemplate = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();

  const { isRegisted } = useAuthHook();


  return (
    <Box className={classes.root}>
      <Header />
      {isRegisted() && <Sidebar />}
      <SimpleBarReact className={classes.mainContent}>
        <main style={{ padding: '30px 40px 40px'}}>
          {isRegisted() && <Breadcrumb />}
          {props.children}
        </main>
      </SimpleBarReact>
    </Box>
  );
});

export default PageTemplate;
