import {
  INotificationDeleteResponse,
  INotificationList,
  INotificationListRequest,
  INotificationUpdateResponse,
  INotificationbyIdRequest,
  INotificationbyId,
  TResponse
} from '../../../types/notification'
import apiSlice from '../../api/apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['NotificationHistoryList']
})

export const notificationSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    notificationList: builder.query<
      TResponse<INotificationList>,
      INotificationListRequest
    >({
      query: ({
        searchText,
        currentPageNumber,
        rowLimitPerPage,
        colName,
        sortDirection,
        serverRegionIdList
      }) => ({
        url: `/private/notification-list?searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${sortDirection}&serverRegionIdList=${serverRegionIdList.join(",")}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),

      providesTags: ['NotificationHistoryList']
    }),
    createNotification: builder.mutation<any, any>({
      query: (body: { [key: string]: string }) => ({
        url: '/private/create-notification',
        method: 'POST',
        body
      }),
      invalidatesTags: (result) => (result ? ['NotificationHistoryList'] : [])
    }),
    deleteNotification: builder.mutation<INotificationDeleteResponse, any>({
      query: (body: { [key: string]: string }) => ({
        url: `/private/delete-notification`,
        method: 'DELETE',
        body,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['NotificationHistoryList']
    }),
    getNotificationbyId: builder.query<TResponse<INotificationbyId>, INotificationbyIdRequest>({
      query: ({ notification_id, server_region_id }) => ({
        url: `/private/get-notification-detail?notificationId=${notification_id}&serverRegionId=${String(server_region_id)}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),

      providesTags: ['NotificationHistoryList']
    }),
    updateNotification: builder.mutation<INotificationUpdateResponse, any>({
      query: (body: { [key: string]: string }) => ({
        url: '/private/update-notification',
        method: 'PUT',
        body
      }),
      invalidatesTags: (result) => (result ? ['NotificationHistoryList'] : [])
    })
  })
})

export const {
  useLazyNotificationListQuery,
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useLazyGetNotificationbyIdQuery,
  useUpdateNotificationMutation
} = notificationSlice
