import { useEffect, useState } from 'react'
import { Box, Skeleton } from '@mui/material'
import SimpleBar from 'simplebar-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import PageTemplate from '../PageTemplate'
import { useStyles } from '../../../themes'
import Table from '../../Templates/_Table'
import THeader from '../../Modecules/Table/THeader'
import usePagination from '../../../hooks/usePagination'
import {
  TABLE_ROW_NUMBER_PER_PAGE,
  PAGE_PATH_NAME,
  Product_Types,
  overwriteSimEnums,
  modeEnums
} from '../../../constants/constants'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { IColumnProps } from '../../../types/_table'
import TRow from '../../Modecules/_Table/TRow'
import TBody from '../../Modecules/_Table/TBody'
import TCell from '../../Modecules/_Table/TCell'
import Loader from '../../Atoms/Loader/Loader'
import Pagination from '../../Modecules/Pagination/Pagination'
import CustomTooltip from '../../Atoms/Tooltip'
import { useLazyShippingDataDetailsListQuery } from '../../../state/features/shipping-data/shippingDataSlice'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import {
  SalesRegion,
  useLazyRegionListQuery
} from '../../../state/features/serverRegion/serverRegionSlice'
import { getFormattedeSIMValidDuration } from '../../../utils/utility'

const ShippingDataImportDetails = (): JSX.Element => {
  const classes = useStyles()
  const location = useLocation()
  const id = (location.state as any)?.shipping_details_id
  const navigate = useNavigate()

  // const [itemCodeList, setItemCodeList] = useState<ItemCodeList[]>([])
  const [deviceSelsRegionList, setDeviceSelsRegionList] = useState<
    SalesRegion[]
  >([])

  const [
    triggerRegionList,
    { data: saleRegionList, isLoading: isLoadingRegionList }
  ] = useLazyRegionListQuery()

  useEffect(() => {
    void triggerRegionList()
  }, [triggerRegionList])

  const [filterQuery, setFilterQuery] = useState({
    id: id as number,
    sortDirection: 'DESC',
    sortColName: 'corpName',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0
  })

  useEffect(() => {
    // setItemCodeList(data?.details || [])

    if (saleRegionList?.details) {
      setDeviceSelsRegionList(saleRegionList?.details)
    }
  }, [saleRegionList])

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })

  const columnProps: IColumnProps = [
    {
      key: 'corp_id',
      name: 'Corporate ID',
      colWidth: 157,
      colMaxWidth: 157,
      isSort: true
    },
    {
      key: 'corp_name',
      name: 'Corporate Name',
      colWidth: 110,
      colMaxWidth: 110,
      isSort: true
    },
    {
      key: 'sales_region',
      name: 'Sales Region',
      colWidth: 90,
      colMaxWidth: 90,
      isSort: true
    },
    {
      key: 'esim_valid_duration',
      name: 'eSIM Valid Duration',
      colWidth: 125,
      colMaxWidth: 125,
      isSort: true
    },
    {
      key: 'imei1',
      name: 'IMEI1',
      colWidth: 115,
      colMaxWidth: 115,
      isSort: true
    },
    {
      key: 'imei2',
      name: 'IMEI2',
      colWidth: 115,
      colMaxWidth: 115,
      isSort: true
    },
    {
      key: 'product_type_id',
      name: 'Device Model',
      colWidth: 114,
      colMaxWidth: 114,
      isSort: true
    },
    {
      key: 'mode',
      name: 'Mode',
      colWidth: 130,
      colMaxWidth: 130,
      isSort: true
    },
    {
      key: 'overwrite_sim',
      name: 'Overwrite SIM',
      colWidth: 225,
      colMaxWidth: 225,
      isSort: true
    }
  ]

  const [
    trigger,
    {
      data: shippingDataDetails,
      isSuccess: shippingDataDetailsIsSuccess,
      isFetching: shippingDataDetailsIsReloading,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error: shippingDataDetailsError
    }
  ] = useLazyShippingDataDetailsListQuery()
  useEffect(() => {
    if (shippingDataDetailsError) {
      if (shippingDataDetailsError?.data?.result_code === 'INFO_NO_FILE') {
        toast.error(shippingDataDetailsError?.data.message)
        navigate(PAGE_PATH_NAME.SHIPPING_DATA_IMPORT)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingDataDetailsError])

  const {
    currentPage,
    // setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    // setRowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    shippingDataDetails ? shippingDataDetails?.total_count : 0
  )

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  const sortFieldsNameReplace = (substr: string) => {
    if (substr === 'corp_id') {
      return 'corpId'
    }
    if (substr === 'corp_name') {
      return 'corpName'
    }
    if (substr === 'imei1') {
      return 'imei1'
    }
    if (substr === 'imei2') {
      return 'imei2'
    }
    if (substr === 'product_type_id') {
      return 'productType'
    }
    if (substr === 'sales_region') {
      return 'salesRegion'
    }
    if (substr === 'esim_valid_duration') {
      return 'eSimValidDuration'
    }
    if (substr === 'mode') {
      return 'mode'
    }
    if (substr === 'overwrite_sim') {
      return 'overwriteSim'
    }

    return ''
  }

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate.key === '' ? 'DESC' : sortConfigUpdate.direction,
      sortColName:
        sortConfigUpdate.direction === DIRECTION_TYPE.none
          ? 'corpName'
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }
  const getPageDataIndexTo = () => {
    if (shippingDataDetails) {
      if ((currentPage + 1) * rowsPerPage > shippingDataDetails.total_count) {
        return shippingDataDetails.total_count
      }

      return (currentPage + 1) * rowsPerPage
    }

    return 0
  }

  return (
    <PageTemplate>
      <Box className={classes.newTableCard}>
        <Box sx={{ display: 'flex', fontSize: '14px', height: 48 }}>
          <Box>
            <div
              style={{ fontWeight: 'bold', marginBottom: 6, color: '#313133' }}
            >
              File Name
            </div>
            {shippingDataDetailsIsReloading ? (
              <Skeleton variant='rectangular' height={20} />
            ) : (
              <CustomTooltip
                toolTipTitle={shippingDataDetails?.file_name ?? '-'}
                maxWidth={185}
                lineHeight='unset'
                height='unset'
              />
            )}
          </Box>
          <Box sx={{ paddingLeft: '113px' }}>
            <div
              style={{ fontWeight: 'bold', marginBottom: 6, color: '#313133' }}
            >
              Registrant
            </div>
            <div>
              {shippingDataDetailsIsReloading ? (
                <Skeleton variant='rectangular' height={20} />
              ) : (
                <CustomTooltip
                  toolTipTitle={shippingDataDetails?.registered_by ?? '-'}
                  maxWidth={120}
                  lineHeight='unset'
                  height='unset'
                />
              )}
            </div>
          </Box>
          <Box sx={{ paddingLeft: '172px' }}>
            <div
              style={{ fontWeight: 'bold', marginBottom: 6, color: '#313133' }}
            >
              Import Date
            </div>
            {shippingDataDetailsIsReloading ? (
              <Skeleton variant='rectangular' height={20} />
            ) : (
              <CustomTooltip
                toolTipTitle={
                  shippingDataDetails?.registered_date
                    ? format(
                        new Date(shippingDataDetails?.registered_date),
                        'MMM d, yyyy  HH:mm'
                      )
                    : '-'
                }
                maxWidth={142}
                lineHeight='unset'
                height='unset'
              />
            )}
          </Box>
        </Box>
        <Box sx={{ marginTop: '40px', fontWeight: 'bold' }}>
          File Contents ({shippingDataDetails?.total_count ?? 0})
        </Box>
        <Box
          className={classes.dataTableWrap}
          sx={{ marginTop: '6px !important' }}
        >
          <SimpleBar>
            <Table tableWidth='1528px'>
              <THeader
                clickSortButton={onClickSortButton}
                sortConfig={sortConfig}
                columnProperties={columnProps}
              />
              <TBody
                isSuccess={shippingDataDetailsIsSuccess}
                columnProperties={columnProps}
                customHeight='calc(100vh - 499px)'
              >
                {/* eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unsafe-call */}
                {shippingDataDetails?.details?.map((element) => {
                  return (
                    <TRow key={element.id} index={element.id}>
                      <TCell
                        colMaxWidth={columnProps[0].colMaxWidth}
                        colWidth={columnProps[0].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[0].key}
                      >
                        {element.corp_id ? (
                          <CustomTooltip
                            toolTipTitle={element.corp_id}
                            maxWidth={columnProps[0].colWidth}
                          />
                        ) : (
                          '-'
                        )}
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[1].colMaxWidth}
                        colWidth={columnProps[1].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[1].key}
                      >
                        {element.corp_name ? (
                          <CustomTooltip
                            toolTipTitle={element.corp_name}
                            maxWidth={columnProps[1].colWidth}
                          />
                        ) : (
                          '-'
                        )}
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[2].colMaxWidth}
                        colWidth={columnProps[2].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[2].key}
                      >
                        {deviceSelsRegionList?.find(
                          (item: SalesRegion) =>
                            item?.salesRegionId === Number(element.sales_region)
                        )?.salesRegion || '-'}
                      </TCell>

                      <TCell
                        colMaxWidth={columnProps[3].colMaxWidth}
                        colWidth={columnProps[3].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[3].key}
                      >
                        {getFormattedeSIMValidDuration(
                          element?.esim_valid_duration,
                          false
                        )}
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[4].colMaxWidth}
                        colWidth={columnProps[4].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[4].key}
                      >
                        {element.imei1 ? (
                          <CustomTooltip
                            toolTipTitle={element.imei1}
                            maxWidth={columnProps[4].colWidth}
                          />
                        ) : (
                          '-'
                        )}
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[5].colMaxWidth}
                        colWidth={columnProps[5].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[5].key}
                      >
                        {element.imei2 ? (
                          <CustomTooltip
                            toolTipTitle={element.imei2}
                            maxWidth={columnProps[5].colWidth}
                          />
                        ) : (
                          '-'
                        )}
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[6].colMaxWidth}
                        colWidth={columnProps[6].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[6].key}
                      >
                        {element.product_type_id
                          ? Product_Types[element.product_type_id]
                          : '-'}
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[7].colMaxWidth}
                        colWidth={columnProps[7].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[7].key}
                      >
                        {element.mode ? (
                          <CustomTooltip
                            toolTipTitle={
                              modeEnums[Number(element?.mode)] || '-'
                            }
                            maxWidth={columnProps[7].colWidth}
                          />
                        ) : (
                          '-'
                        )}
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[8].colMaxWidth}
                        colWidth={columnProps[8].colWidth}
                        isReloading={
                          shippingDataDetailsIsReloading || isLoadingRegionList
                        }
                        key={columnProps[8].key}
                      >
                        {overwriteSimEnums[Number(element?.overwrite_sim)] ||
                          '-'}
                      </TCell>
                    </TRow>
                  )
                })}
              </TBody>
            </Table>
          </SimpleBar>
          {shippingDataDetailsIsReloading && shippingDataDetailsIsSuccess ? (
            <Box
              sx={{
                width: '40px',
                height: '40px',
                position: 'absolute',
                top:
                  // eslint-disable-next-line no-nested-ternary
                  shippingDataDetails?.total_count === 1
                    ? '10%'
                    : shippingDataDetails?.total_count === 2
                    ? '25%'
                    : '45%',
                left: '50%',
                zIndex: '1'
              }}
            >
              <Loader colorCode='#939598' />
            </Box>
          ) : null}
          <div className={classes.dataPagination}>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(
                // eslint-disable-next-line no-unsafe-optional-chaining
                (shippingDataDetails ? shippingDataDetails.total_count : 0) /
                  rowsPerPage
              )}
              clickPrevPageButton={onClickPrevPage}
              clickNextPageButton={onClickNextPage}
              rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
              currentRowNumberPerPage={rowsPerPage}
              changeRowNumber={onChangeRowNumber}
              containFirstAndLastButton
              clickFirstPageButton={onClickFirstPage}
              clickLastPageButton={onClickLastPage}
              pageDataIndexFrom={currentPage * rowsPerPage + 1}
              pageDataIndexTo={getPageDataIndexTo()}
              totalDataRows={
                shippingDataDetails ? shippingDataDetails?.total_count : 0
              }
            />
          </div>
        </Box>
      </Box>
    </PageTemplate>
  )
}

export default ShippingDataImportDetails
