/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { Box, DialogActions, Typography } from '@mui/material'
import PageTemplate from '../PageTemplate'
import AddUserDialog from '../../Modecules/AddUser'
import Button from '../../Atoms/Buttons'
import { useStyles } from '../../../themes'
import Table from '../../Templates/_Table'
import Pagination from '../../Modecules/Pagination/Pagination'
import { TABLE_ROW_NUMBER_PER_PAGE } from '../../../constants/constants'
import usePagination from '../../../hooks/usePagination'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { IColumnProps } from '../../../types/table'
import Loader from '../../Atoms/Loader/Loader'
import THeader from '../../Modecules/Table/THeader'
import TBody from '../../Modecules/_Table/TBody'
import TRow from '../../Modecules/_Table/TRow'
import TCell from '../../Modecules/_Table/TCell'
import CustomModal from '../../Atoms/CustomModal'
import { ReactComponent as UploadIcon } from '../../../images/icon/update-white.svg'
import { ReactComponent as UploadIconBlue } from '../../../images/icon/update.svg'
import { ReactComponent as CsvIcon } from '../../../images/icon/shipping-csv-icon.svg'
import CustomTooltip from '../../Atoms/Tooltip'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import {} from '../../../types/shipping-data'
import {
  useLazyRegionlMappingListQuery,
  useEditRegionMappingMutation
} from '../../../state/features/regionmapping/regionmappingSlice'
import { OptionsType } from '../../../types/common'
import CustomRegionFilter from '../../Modecules/RegionFilter/_index'
import useCsvValidator from '../../../hooks/useCsvValidator'
import RotatingIconLoader from '../../Atoms/RotatingIconLoader/RotatingIconLoader'
import {
  useLazyRegionListQuery,
  SalesRegion
} from '../../../state/features/serverRegion/serverRegionSlice'

type Option = {
  label: string
  value: string
}

const RegionMappingList = (): JSX.Element => {
  const [addUserModal, setAddUserModal] = useState<boolean>(false)
  const [loadingState, setLoadingState] = useState<boolean>(false)
  const [selectedRowData, setSelectedRowData] = useState<{
    id: number
    selectedServerRegion: OptionsType
    selectedSalesRegion: OptionsType
  } | null>(null)
  const [response, setResponse] = useState<{
    status: boolean
    message: string
  } | null>(null)
  const [options, setOptions] = useState<Option[]>([])
  const [openNewImportModal, setOpenNewImportModal] = useState<boolean>(false)
  const { fileInfo } = useCsvValidator()

  const classes = useStyles()
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })
  const { t } = useTranslation()

  useEffect(() => {
    if (response && response.status) {
      setAddUserModal(false)
      toast.success(response.message)
    } else if (response && !response.status) {
      setAddUserModal(false)
      toast.error(response.message)
    }
  }, [response])

  const [filterQuery, setFilterQuery] = useState({
    sortDirection: 'ASC',
    colName: 'id',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0
  })

  const [
    trigger,
    {
      data: regionMappingList,
      isSuccess: regionMappingListIsSuccess,
      isFetching: regionMappingListIsReloading,
      isError: regionMappingListIsError,
      error: regionMappingListError
    }
  ] = useLazyRegionlMappingListQuery()

  const [
    triggerRegionList,
    {
      data: regionList,
      isSuccess: regionListIsSuccess,
      // isFetching: regionListIsReloading,
      isError: regionListIsError,
      error: regionListError
    }
  ] = useLazyRegionListQuery()

  const [
    editRegionMapping,
    {
      // data: editRegionMappingData,
      isSuccess: editRegionMappingIsSuccess,
      isError: editRegionMappingIsError,
      error: editRegionMappingError
    }
  ] = useEditRegionMappingMutation()

  useEffect(() => {
    void triggerRegionList()
  }, [triggerRegionList, selectedRowData])

  useEffect(() => {
    if (regionListIsSuccess && regionList?.details) {
      const convertedOptions = regionList.details.map(
        (region: SalesRegion) => ({
          label: region.salesRegion,
          value: region.salesRegion
        })
      )
      if(selectedRowData && selectedRowData?.selectedSalesRegion?.value === '') {
        setOptions([{ label: 'N/A', value: '' }, ...convertedOptions])
      }else{
        setOptions(convertedOptions)
      }
      
    } else if (regionListIsError) {
      setOptions([{ label: 'N/A', value: '' }])
    }
  }, [regionListIsSuccess, regionListIsError, regionList, regionListError , selectedRowData])

  const {
    currentPage,
    // setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    // setRowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    // regionMappingList ? regionMappingList?.data.data.total_region_mapping_list : 0
    regionMappingList ? regionMappingList.data.total_region_mapping_list : 0
  )

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  const sortFieldsNameReplace = (substr: string) => {
    if (substr === 'server_region') {
      return 'serverRegion'
    }
    if (substr === 'sales_region') {
      return 'salesRegion'
    }

    return ''
  }

  useEffect(() => {
    if (regionMappingListIsError) {
      // console.log(editError)
      if (regionMappingListError?.data?.message) {
        toast.error(regionMappingListError?.data?.message)
      } else {
        toast.error('Something went wrong')
      }
    }
  }, [regionMappingListError?.data?.message, regionMappingListIsError])

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate?.key === '' ? 'ASC' : sortConfigUpdate.direction,
      colName:
        sortConfigUpdate?.direction === DIRECTION_TYPE.none
          ? 'id'
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }



  const handleUpdateRegionMapping = async () => {
    setLoadingState(true)
    if (selectedRowData) {
      await editRegionMapping({
        id: selectedRowData?.id,
        sales_region: selectedRowData?.selectedSalesRegion?.value
      })
    }
    setLoadingState(false)
  }

  useEffect(() => {
    if (editRegionMappingIsSuccess) {
      toast.success('Region Mapping updated successfully')
      setSelectedRowData(null)
      setOpenNewImportModal(false)
      void trigger(filterQuery, false)
    }
    if (editRegionMappingIsError) {
      toast.error(editRegionMappingError?.data?.message || 'Something went wrong')
    }
  }, [editRegionMappingIsSuccess, editRegionMappingIsError, editRegionMappingError])

  const columnProps: IColumnProps = [
    {
      key: 'server_region',
      name: 'Server Region',
      colWidth: 130,
      colMaxWidth: 130,
      isSort: true
    },
    {
      key: 'sales_region',
      name: 'Sales Region',
      colWidth: 100,
      colMaxWidth: 100,
      isSort: true
    },
    {
      key: 'last_updated',
      name: 'Last Updated',
      colWidth: 120,
      colMaxWidth: 120,
      isSort: false
    },
    {
      key: 'actions',
      name: '',
      colWidth: 80,
      colMaxWidth: 80,
      isSort: false
    }
  ]

  return (
    <PageTemplate>
      <CustomModal
        isOpen={openNewImportModal}
        onClose={() => {
          setOpenNewImportModal(false)
        }}
        title='Edit Region Mapping'
      >
        <Box
          sx={{
            display: fileInfo && 'flex',
            gap: '30px',
            width: '100%',
            padding: '15px 0 0 0'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px'
            }}
          >
            <CustomRegionFilter
              disabled
              width='516px'
              placeholder='Sales Region'
              selectedOption={
                selectedRowData?.selectedServerRegion || {
                  label: '',
                  value: ''
                }
              }
              options={[]}
              labelText='Server Region'
            />
            <CustomRegionFilter
              width='516px'
              disabled={false}
              placeholder='Sales Region'
              selectedOption={
                selectedRowData?.selectedSalesRegion || {
                  label: '',
                  value: ''
                }
              }
              optionChangeHandler={(option: OptionsType) => {
                // eslint-disable-next-line no-unused-expressions
                selectedRowData &&
                  setSelectedRowData({
                    ...selectedRowData,
                    selectedSalesRegion: option
                  })
              }}
              options={options}
              labelText='Sales Region'
              height='180px'
            />

            <DialogActions
              className={classes.btnBox}
              sx={{
                marginTop: '8px'
              }}
            >
              <Button
                variant='outlined'
                className={classes.cancelBtn}
                onClick={() => setOpenNewImportModal(false)}
                disabled={loadingState}
              >
                Cancel
              </Button>
              <Button
                variant='outlined'
                startIcon={
                  <RotatingIconLoader isRotating={loadingState}>
                    <UploadIcon />
                  </RotatingIconLoader>
                }
                className={classes.importBtnRegionMapping}
                sx={{
                  width: '120px !important'
                }}
                onClick={()=>{
                  void handleUpdateRegionMapping()
                }}
                disabled={ 
                  loadingState || 
                  !selectedRowData || 
                  !!regionMappingList?.data.region_mapping_list?.find(
                    (item) => 
                      item.id === selectedRowData?.id && 
                      item.sales_region === selectedRowData?.selectedSalesRegion?.value
                  )
                }
                              >
                Update
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </CustomModal>
      <AddUserDialog
        handleClose={() => {
          setAddUserModal(false)
        }}
        open={addUserModal}
        setResponse={setResponse}
      />
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('regionMappingList.headerTitle')}`} (
            {regionMappingList?.data.total_region_mapping_list ?? 0})
          </Typography>
        </div>

        {regionMappingList?.data.total_region_mapping_list === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <CsvIcon />
            <p
              className={`${classes.noFountText} ${classes.mt_0}`}
              style={{ marginTop: '4px' }}
            >
              {t('regionMappingList.noDataTitle')}
            </p>
            <p className={`${classes.noAvailableText} ${classes.mt_0}`}>
              {t('regionMappingList.noDataMessage')}
            </p>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table>
                <THeader
                  clickSortButton={onClickSortButton}
                  sortConfig={sortConfig}
                  columnProperties={columnProps}
                />
                <TBody
                  isSuccess={regionMappingListIsSuccess}
                  columnProperties={columnProps}
                >
                  {regionMappingList?.data.region_mapping_list?.map(
                    (element) => (
                      <TRow key={element.id} index={element.id}>
                        <TCell
                          colMaxWidth={columnProps[0].colMaxWidth}
                          colWidth={columnProps[0].colWidth}
                          isReloading={regionMappingListIsReloading}
                          key='server_region'
                        >
                          {element.server_region}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[1].colMaxWidth}
                          colWidth={columnProps[1].colWidth}
                          isReloading={regionMappingListIsReloading}
                          key='sales_region'
                        >
                          {element?.sales_region !== '' ? (
                            <CustomTooltip
                              toolTipTitle={element.sales_region}
                              maxWidth={columnProps[1].colWidth}
                              fontSize="14px"
                            />
                          ) : (
                            'N/A'
                          )}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[2].colMaxWidth}
                          colWidth={columnProps[2].colWidth}
                          isReloading={regionMappingListIsReloading}
                          key='last_updated'
                        >
                          {element.last_updated}
                          {/* {format(
                            new Date(element.last_used),
                            'MMM d, yyyy  HH:mm'
                          )} */}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[3].colMaxWidth}
                          colWidth={columnProps[3].colWidth}
                          isReloading={regionMappingListIsReloading}
                          key='actions'
                        >
                          <button
                            type='button'
                            style={{
                              color: '#007EB4',
                              cursor: 'pointer',
                              fontSize: '12px',
                              backgroundColor: 'transparent',
                              border: 'none',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                              fontFamily: "'Noto Sans JP', sans-serif"
                            }}
                            onClick={() => {
                              setOpenNewImportModal(true)
                              setSelectedRowData({
                                id: element.id,
                                selectedSalesRegion: {
                                  label: element.sales_region,
                                  value: element.sales_region
                                },
                                selectedServerRegion: {
                                  label: element.server_region,
                                  value: element.server_region
                                }
                              })
                            }}
                          >
                            <UploadIconBlue style={{ height: '17px' }} />
                            Update
                          </button>
                        </TCell>
                      </TRow>
                    )
                  )}
                </TBody>
              </Table>
            </SimpleBar>
            {regionMappingListIsReloading && regionMappingListIsSuccess ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top:
                    // eslint-disable-next-line no-nested-ternary
                    regionMappingList?.data.total_region_mapping_list === 1
                      ? '10%'
                      : regionMappingList?.data.total_region_mapping_list === 2
                      ? '25%'
                      : '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode='#939598' />
              </Box>
            ) : null}
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  (regionMappingList
                    ? regionMappingList.data.total_region_mapping_list ?? 0
                    : 0) / rowsPerPage
                )}
                clickPrevPageButton={() => {
                  onClickPrevPage()
                }}
                clickNextPageButton={() => {
                  onClickNextPage()
                }}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={
                  // eslint-disable-next-line no-nested-ternary
                  (currentPage + 1) * rowsPerPage >
                  (regionMappingList
                    ? regionMappingList.data.total_region_mapping_list
                    : 0)
                    ? regionMappingList
                      ? regionMappingList.data.total_region_mapping_list
                      : 0
                    : (currentPage + 1) * rowsPerPage
                }
                totalDataRows={
                  regionMappingList
                    ? regionMappingList.data.total_region_mapping_list
                    : 0
                }
              />
            </div>
          </Box>
        )}
      </Box>
    </PageTemplate>
  )
}

export default RegionMappingList
