/* eslint-disable react/require-default-props */
import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { Box, Dialog, DialogTitle, IconButton, Typography } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg'

type CustomModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  title?: string
  icon?: React.ReactNode
  width?: string
  borderPadding?: string
}


interface ModalWindowProps {
  width?: string
  padding ?: string
  icon?: React.ReactNode
}


const CustomDialogTitle: React.FC<{ 
  onClose: () => void
  icon?: React.ReactNode
 }> = ({
  onClose,
  icon,
  children
}) => (
  <DialogTitle>
    {children}
    <IconButton
      aria-label='close'
      onClick={onClose}
      sx={{
        position: 'absolute',
        padding: '8px',
        right: icon ? 16 : 24,
        top: icon ? 16 : 24,
        '&:focus': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ModalWindow = styled(Dialog)<ModalWindowProps>(({ width , padding , icon }) => ({
  '& .MuiDialog-paper': {
    minWidth: width || '580px', // Use custom width or default to '580px'
    borderRadius: '10px',
    maxWidth: 'unset !important',
    padding: `24px 0 ${padding || '32px'}`,
    position: 'relative',
    overflow: 'hidden',
    margin: 0
  },
  '& .MuiDialogTitle-root': {
    padding: `0 24px 0px ${icon ? '37px' : '32px'}`,
  },
  '& .MuiDialogActions-root': {
    padding: `0 32px `,
    justifyContent: 'left'
  },
  '& .MuiDialog-container': {
    height: 'unset !important',
    marginTop: '100px',
    '& :not(:first-of-type)': {
      marginLeft: '0'
    },
    '@media screen and (max-width: 1366px)': {
      marginTop: '25px'
    }
  }
}))
const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  children,
  title = 'New Import',
  icon,
  width,
  borderPadding
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  return (
    <Box>
      <ModalWindow open={isOpen} width={width}
        padding={borderPadding} icon={icon}
        onClose={onClose}
      >
        <Box
          sx={{
            display: icon ? 'flex' : 'block',
            alignItems: 'start',
          }}
        >
          {icon && (
            <Box
              sx={{
                paddingLeft: '24px',
                display: 'inline',
                height: '72px',
                width: '72px',
                paddingRight: '14px'
              }}
            >
              {icon}
            </Box>
          )}
          <Box>
            <CustomDialogTitle onClose={onClose}
              icon={icon}
            
            >
              <Typography
                fontSize='18px'
                fontWeight='bold'
                lineHeight='26px'
                color='#232F3E'
              >
                {title}
              </Typography>
            </CustomDialogTitle>

            <Box sx={{ padding: `0  ${borderPadding || '32px'} `}}>
              <div ref={modalRef}>{children}</div>
            </Box>
          </Box>
        </Box>
      </ModalWindow>
    </Box>
  )
}

export default CustomModal
