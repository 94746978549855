/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IDeviceDetailResponse,
  IDeviceListResponse,
  deviceSearchParamType
} from '../../../types/device'
import apiSlice from '../../api/apiSlice'

export interface IDeviceDetailParams {
  device_id?: number
  imei1?: number | string
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['DeviceList']
})

export const deviceSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    deviceList: builder.query<IDeviceListResponse, deviceSearchParamType>({
      query: ({
        searchText,
        currentPageNumber,
        rowLimitPerPage,
        colName,
        sortDirection,
        serverRegionIdList
      }: any) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `private/devicelist?searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${sortDirection}&serverRegionIdList=${serverRegionIdList}`,

        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),

      providesTags: ['DeviceList']
    }),
    deviceDetail: builder.query<IDeviceDetailResponse, IDeviceDetailParams>({
      query: ({ imei1 }) => ({
        url: `private/device-details?imei1=${imei1}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }),
    suspendDevice: builder.mutation<IDeviceDetailResponse, any>({
      query: (body) => ({
        url: `private/update-suspension`,
        method: 'PUT',
        body: body,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }),
    deviceListSearch: builder.mutation<any, any>({
      query: ({
        searchText,
        currentPageNumber,
        rowLimitPerPage,
        sortColName,
        sortDirection
      }: deviceSearchParamType) => ({
        url: `private/devicelist?searchText=${
          searchText ?? ''
        }&currentPageNumber=${currentPageNumber ?? ''}&rowLimitPerPage=${
          rowLimitPerPage ?? ''
        }&colName=${sortColName ?? ''}&sortDirection=${sortDirection ?? ''}`,
        method: 'GET',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
        // body: queryParam
      })
    }),
    deleteDevice: builder.mutation<any, IDeviceDetailParams>({
      query: ({ imei1 }) => ({
        url: `/private/device-delete?imei1=${imei1}`,
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['DeviceList']
    })
  })
})

export const {
  useDeviceListQuery,
  useLazyDeviceListQuery,
  useDeviceListSearchMutation,
  useDeleteDeviceMutation,
  useSuspendDeviceMutation,
  useLazyDeviceDetailQuery
} = deviceSlice
