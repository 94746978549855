import apiSlice from '../../api/apiSlice'
import {
  IDeviceUrlMappingListResponse,
  IListRequest
} from '../../../types/deviceurlmapping'
import { TResponse } from '../../../types/notification'
import { IDeivceImportPayload, deviceDeleteParamType } from '../../../types/deviceimport'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['deviceUrlMappingList']
})

export const deviceUrlMappingSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    deviceUrlMappingList: builder.query<
      TResponse<IDeviceUrlMappingListResponse>,
      IListRequest
    >({
      query: ({
        currentPageNumber,
        rowLimitPerPage,
        colName,
        sortDirection
      }) => ({
        url: `/private/device-url-mapping-list?currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${String(
          sortDirection
        )}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }),
    createDeviceUrlMapping: builder.mutation<
      TResponse<{ message: string; device_url_id: number }>,
      IDeivceImportPayload
    >({
      query: (body) => ({
        url: '/private/create-device-url-mapping',
        method: 'POST',
        body
      }),
      invalidatesTags: (result) => (result ? ['deviceUrlMappingList'] : [])
    }),
    editDeviceUrlMapping: builder.mutation<
      TResponse<{ message: string; device_url_id: number }>,
      IDeivceImportPayload
    >({
      query: (body) => ({
        url: '/private/update-device-url-mapping',
        method: 'PUT',
        body
      }),
      invalidatesTags: (result) => (result ? ['deviceUrlMappingList'] : [])
    }),
    deleteDeviceUrlMapping: builder.mutation<
      TResponse<{ message: string; device_url_id: number }>,
      deviceDeleteParamType
    >({
      query: ({ deviceUrlId }) => ({
        url: `/private/delete-device-url-mapping?deviceUrlId=${deviceUrlId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result) => (result ? ['deviceUrlMappingList'] : [])
    })
  })
})

export const {
  useLazyDeviceUrlMappingListQuery,
  useCreateDeviceUrlMappingMutation,
  useEditDeviceUrlMappingMutation,
  useDeleteDeviceUrlMappingMutation
} = deviceUrlMappingSlice
