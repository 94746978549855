import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneIcon from '@mui/icons-material/Done'
import SimpleBar from 'simplebar-react'
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Popper,
  ClickAwayListener,
  Paper
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useStyles } from '../../../themes/index'

const useCustomStyles = makeStyles(() => ({
  deviceImportSimplebarCustom: {
    paddingTop: 0,
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    opacity: '1',
    background: '#FFFFFF 0% 0% no-repeat padding-box'
  },
  regionSelectBox: {
    boxShadow: 'none',
    width: '100%',
    height: '38px',
    fontSize: '14px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    borderRadius: '4px',
    justifyContent: 'space-between',
    opacity: '1',
  }
}))

export interface CustomRegionFilterProps {
  disabled?: boolean
  placeholder?: string
  selectedOption: { label: string; value: string }
  optionChangeHandler?: (option: { label: string; value: string }) => void
  options: { label: string; value: string }[]
  labelText?: string
  topMargin?: string
  width?: string
  height?: string
}

const CustomRegionFilter: React.FC<CustomRegionFilterProps> = ({
  disabled = false,
  placeholder = 'Select Region',
  selectedOption,
  optionChangeHandler,
  options,
  labelText,
  topMargin,
  width,
  height
}) => {
  const classes = useStyles()
  const customClasses = useCustomStyles()

  const [selectAnchor, setSelectAnchor] = useState<null | HTMLElement>(null)
  const [selectOpen, setSelectOpen] = useState<boolean>(false)
  const [selectedRegion, setSelectedRegion] = useState<{
    label: string
    value: string
  } | null>(selectedOption)

  const handleSelectBoxClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setSelectAnchor(event.currentTarget)
    setSelectOpen((prev) => !prev)
  }

  const handleSelectRegion = (option: { label: string; value: string }) => {
    setSelectedRegion(option)
    // eslint-disable-next-line no-unused-expressions
    optionChangeHandler && optionChangeHandler(option)
    setSelectOpen(false)
  }

  return (
    <Box sx={{ marginTop: topMargin }}>
      {labelText && (
        <Typography className={classes.regionSelectTitle}>
          {labelText || 'Filter by Server Regions'}
        </Typography>
      )}
      <FormControl sx={{ width: width || '395px', height: '38px' }}>
        <Box
          onClick={disabled ? undefined : handleSelectBoxClick}
          sx={{
            border: selectOpen ? '1px solid #9AA5B1' : '1px solid #DDDDDD',
            cursor: disabled ? 'default' : 'pointer',
            background: disabled ? '#F8F8F8 0% 0% no-repeat padding-box': '#FFFFFF 0% 0% no-repeat padding-box',
            '&:hover': {
              border: disabled ? null : '1px solid #939598'
            },
          
          }}
          className={customClasses.regionSelectBox}
        >
          <Box className={classes.selectedRegionCard}>
            {selectedRegion ? (
              <Typography className={classes.regionSelectPlaceholder}
                sx={{
                    color: disabled ? '#6C7778' : '#313133 !important',
                    padding: '0px 2px !important',
                }}
              
              >
                {selectedRegion.label !== "" ? selectedRegion.label : 'N/A' }
              </Typography>
            ) : (
              <Typography className={classes.regionSelectPlaceholder}>
                {placeholder}
              </Typography>
            )}
          </Box>
          {disabled ? null : (
            <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px' }} />
          )}
        </Box>
        <Popper
          placement='bottom-start'
          anchorEl={selectAnchor}
          open={selectOpen}
          className={classes.selectRegionModel}
        >
          <ClickAwayListener onClickAway={() => setSelectOpen(false)}>
            <Paper
              className={customClasses.deviceImportSimplebarCustom}
              style={{
                overflow: 'auto',
                width: width || '393px'
              }}
            >
              <SimpleBar style={{ maxHeight: height || '200px' }}>
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    className={classes.regionItem}
                    sx={{
                      backgroundColor:
                        selectedRegion?.value === option.value
                          ? '#FAFAFA'
                          : '#ffffff',
                      width: width ? `100%` : '393px'
                    }}
                    value={option.value}
                    onClick={() => handleSelectRegion(option)}
                  >
                    <Typography
                      className={classes.regionItemText}
                      sx={{
                        width:
                          selectedRegion?.value === option.value
                            ? '208px'
                            : '228px',
                        color:
                          selectedRegion?.value === option.value
                            ? '#232F3E'
                            : '#6C7778'
                      }}
                    >
                      {option.label}
                    </Typography>
                    {selectedRegion?.value === option.value && (
                      <DoneIcon
                        sx={{
                          height: '20px',
                          width: '20px',
                          color: '#009DE1'
                        }}
                      />
                    )}
                  </MenuItem>
                ))}
              </SimpleBar>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </FormControl>
    </Box>
  )
}

export default CustomRegionFilter
