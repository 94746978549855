import apiSlice from '../../api/apiSlice'
import { IDeviceUrlEditPayload, IListRequest } from '../../../types/deviceurlmapping'
import { TResponse } from '../../../types/notification'
import { IRegionMappingListResponse } from '../../../types/regionmapping'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['deviceUrlMappingList']
})

export const regionMappingSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    regionlMappingList: builder.query<
      TResponse<IRegionMappingListResponse>,
      IListRequest
    >({
      query: ({
        currentPageNumber,
        rowLimitPerPage,
        colName,
        sortDirection
      }) => ({
        url: `/private/region-mapping-list?currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${String(
          sortDirection
        )}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }),
    editRegionMapping: builder.mutation<
    TResponse<{ message: string; device_url_id: number }>,
    IDeviceUrlEditPayload>({
      query: (body) => ({
        url: '/private/update-region-mapping',
        method: 'PUT',
        body
      })
    })
  })
})

export const { useLazyRegionlMappingListQuery , useEditRegionMappingMutation} = regionMappingSlice
