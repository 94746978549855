import { makeStyles } from '@mui/styles'
import { ptColors } from '../../../themes/settings/colors'

const ShippingDataModalTableStyle = makeStyles({
  iconBtn: {
    position: 'absolute',
    padding: '8px',
    right: 24,
    top: 24,
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  errorBlock: {
    paddingTop: "7px",
    paddingBottom: 2,
    height: 'calc(100% - 60px)',
    paddingRight: '32px'
  },

  errorItem: {
    display: 'flex',
    marginBottom: 7,
    '& .MuiTypography-root': {
      lineHeight: '18px',
      fontWeight: '400'
    }
  },

  errorText: {
    fontWeight: 500,
    fontSize: 13,
    marginLeft: '5px !important',
    textAlign: 'justify',
    color: `${ptColors.white}`,
  },

  errorLine: {
    color: `${ptColors.white}`,
    fontWeight: 500,
    fontSize: 13,
    minWidth: '78px',
    paddingLeft: '59px',
    lineHeight: '22px',
  },
  errorLineClamp: {
    color: `${ptColors.white}`,
    fontWeight: 500,
    fontSize: 13,
    minWidth: '78px',
    paddingLeft: '59px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    marginRight: '20px'

  },


  errorDialogBlock: {
    position: 'absolute',

    width: 580,
    borderRadius: 10,
    zIndex: 1,
    top: 0,
    left: 0
  },
  errorContentBlock: {
    backgroundColor: `${ptColors.colorRed}`,
    padding: '26px 0 13px 32px',
    height: '80%',
    margin: "8px",
    borderRadius: "3px"

  },
  errorTitleBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '24px',
    gap: '10px'
  },
  errorTitle: {
    fontSize: 18,
    marginBottom: 0,
    color: `${ptColors.white}`,
    fontWeight: 700,
    height: '24px',
    lineHeight: '26px',
    marginTop: 0
  },
  showMoreBlock: {
    
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    background: 'none',
    border: 'none',
    padding: '2px 0',
    textTransform: 'none',
    '&:hover': {
      background: 'none'
    },
    '&.Mui-focusVisible .MuiTypography-root': {
      color: '#a90000 !important'
    },
    '&.Mui-focusVisible svg path': {
      stroke: '#a90000 !important'
    }
  },
  showMoreText: {
    fontSize: '13px',
    fontWeight: 400,
    color: `${ptColors.white}`,
    marginRight: 10
  }
})

export default ShippingDataModalTableStyle 
