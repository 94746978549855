import React from 'react'
import {
  Drawer,
  styled,
  Box,
  Theme,
  CSSObject,
  Typography,
  Button,
  DialogActions
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { toast } from 'react-toastify'
import MainMenu from './MainMenu'
import { useStyles } from '../../themes/index'
import CustomModal from '../Atoms/CustomModal'
import { ReactComponent as ExportIcon } from '../../images/icon/exportDataIcon.svg'
import Loader from '../Atoms/Loader/Loader'

// assets

const drawerWidth = 250
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  border: '0px'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: '250px',
  border: '0px'
})
const MyDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const useCustomModalStyles = makeStyles(() => ({
  customBtnContainer: {
    width: '382px',
    padding: '16px 0px 0 0px !important',
    // marginTop:'20px',
    display: 'flex',
    justifyContent: 'flex-end !important',
    alignItems: 'center',
    columnGap: '10px'
  },
  cancelBtn: {
    color: '#232F3E',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #DDDDDD',
    height: '44px',
    padding: '10px 19px',
    borderRadius: '4px',
    margin: '0 0 0 7px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      border: '1px solid #D1D1D1',
      color: '#000'
    },
    '&:focus': {
      outline: '4px solid #CCEBF9',
      color: '#000'
    }
  },

  importBtn: {
    color: '#ffffff !important',
    fontWeight: '500',
    backgroundColor: '#007EB4',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #0078AB !important',
    height: '44px',
    padding: '10px 20px',
    borderRadius: '4px',
    margin: '0 0 0 7px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#006D9C !important',
      border: '1px solid #0078AB'
    },
    '& .MuiButton-startIcon': {
      marginLeft: '0',
      marginRight: '6px'
    },
    '&:focus': {
      outline: '5px solid #CCEBF9'
    }
  }
}))

/**
 * ヘッダー コンポーネント
 *
 * @returns
 */
const Sidebar = (): JSX.Element => {
  const modalClass = useCustomModalStyles()

  // 共通スタイル
  const classes = useStyles()
  // メインメニュー hooks

  const [open, setOpen] = React.useState(false)
  const [isExport, setIsExport] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleExport = () => {
    setIsExport(true)
    setTimeout(() => {
      setIsExport(false)
      toast.success('Export CSV file for SFDC.')
      setOpen(false)
    }, 2000)
  }

  return (
    <MyDrawer variant='permanent' anchor='left'>
      <Box className={classes.drawerBox}>
        <MainMenu handleDrawerOpen={handleDrawerOpen} />
      </Box>
      {/* <CustomModal
        isOpen={open}
        onClose={handleDrawerClose}
        title='Export CSV file for SFDC.'
        icon={<ExportIcon />}
        width='500px'
        borderPadding='24px'
      >
        <Box>
          <Typography
            sx={{
              marginTop: '10px',
              height: '20px',
              color: '#232F3E',
              font: 'normal normal 300 14px/22px Noto Sans JP',
              padding: '0 0 0 14px'
            }}
          >
            You can export CSV file for import into Salesforce.
          </Typography>
          <DialogActions className={modalClass.customBtnContainer}>
            <Button
              variant='outlined'
              className={modalClass.cancelBtn}
              onClick={handleDrawerClose}
              // disabled={loadingState}
            >
              Cancel
            </Button>
            <Button
              variant='outlined'
              startIcon={
                isExport && <Loader colorCode='#939598'  />
              }
              className={modalClass.importBtn}
              onClick={handleExport}
            >
              Export
            </Button>
          </DialogActions>
        </Box>
      </CustomModal> */}
    </MyDrawer>
  )
}

export default Sidebar
