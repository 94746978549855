import React, { ReactNode } from 'react'
/* eslint-disable react/self-closing-comp */
import rotatingIconStyles from './RotatingIconLoader.module.scss'

type Props = {
  children?: ReactNode,
  colorCode?: string,
  speed?: number,
  isRotating?: boolean
}

const RotatingIconLoader = React.memo<Props>(
  ({
    children,
    isRotating = false
  }): JSX.Element => (
    <div className={`${isRotating ? rotatingIconStyles.rotating_animation : ''}`}>
        {children}
    </div>
  )
)


export default RotatingIconLoader
