import { Routes, Route, Navigate } from 'react-router-dom'
import React from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import { useGoogleLogin } from '@react-oauth/google'
// import Menu from './components/Pages/Menu'
import 'react-toastify/dist/ReactToastify.css'
import { theme, useStyles } from './themes/index'
import DeviceImport from './components/Pages/DeviceImportList'
import Login from './components/Pages/Login'
import Verify from './components/Pages/Verify'
import './i18n/config'
import UnauthorizedRoute from './utils/unauthorizedRoute'
import PrivateRoute from './utils/privateRoute'
import { ReactComponent as CloseIcon } from './images/icon/close_white.svg'
import UserManagement from './components/Pages/UserManagement'
import CorporateList from './components/Pages/CorporateList'
import { useGoogleLoginMutation } from './state/features/googleAuth/googleAuthSlice'
import { authInfoSlice } from './state/slice/authInfoSlice'
import { useAppDispatch } from './state/store'
import DeviceList from './components/Pages/DeviceList'
import NotificationHistoryList from './components/Pages/NotificationHistoryList'
import { PAGE_PATH_NAME } from './constants/constants'
import NotificationCreate from './components/Pages/NotificationCreate'
import NotificationEdit from './components/Pages/NotificationEdit'
import EulaManagement from './components/Pages/EulaManagement'
import ShippingDataImport from './components/Pages/ShippingDataImport'
import ShippingDataImportDetails from './components/Pages/ShippingDataImportDetails'
import DeviceDetails from './components/Pages/DeviceDetails'
import DeviceUrlMappingList from './components/Pages/DeviceUrlMappingList'
import ExportCSVtoSFDC from './components/Pages/ExportCSVtoSFDC'
import RegionMappingList from './components/Pages/RegionMappingList'

const CloseButton = () => <CloseIcon />

const App = (): JSX.Element => {
  const classes = useStyles()
  const [googleLoginApi, { data, isSuccess }] = useGoogleLoginMutation()
  const [token, setToken] = React.useState<string>('')
  const { saveLoggedIn } = authInfoSlice.actions
  const dispatch = useAppDispatch()
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      setToken(response.access_token)
      dispatch(saveLoggedIn(true))
      await googleLoginApi(response.access_token)
    }
  })

  return (
    <div className='App'>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className='App-layout'>
            <Routes>
              <Route
                path={PAGE_PATH_NAME.MENU}
                element={
                  <PrivateRoute
                    element={
                      <Navigate to={PAGE_PATH_NAME.COMPANY_LIST} replace />
                    }
                  />
                }
              />
              <Route
                path={PAGE_PATH_NAME.LOGIN}
                element={
                  <UnauthorizedRoute
                    element={
                      <Login
                        token={token}
                        login={login}
                        isSuccess={isSuccess}
                        data={data}
                      />
                    }
                  />
                }
              />
              <Route
                path='/verify'
                element={<UnauthorizedRoute element={<Verify />} />}
              />
              <Route
                path={PAGE_PATH_NAME.COMPANY_LIST}
                element={<PrivateRoute element={<CorporateList />} />}
              />
              {/*
              <Route
                path={PAGE_PATH_NAME.COMPANY_DETAILS}
                element={<PrivateRoute element={<CorporateDetails />} />}
              /> */}
              <Route
                path={PAGE_PATH_NAME.DEVICES}
                element={<PrivateRoute element={<DeviceList />} />}
              />

              <Route
                path={PAGE_PATH_NAME.DEVICE_DETAILS}
                element={<PrivateRoute element={<DeviceDetails />} />}
              />

              <Route
                path={PAGE_PATH_NAME.DEVICE_IMPORT}
                element={<PrivateRoute element={<DeviceImport />} />}
              />
              <Route
                path={PAGE_PATH_NAME.USER_MANAGEMENT}
                element={<PrivateRoute element={<UserManagement />} />}
              />
              <Route
                path={PAGE_PATH_NAME.SHIPPING_DATA_IMPORT}
                element={<PrivateRoute element={<ShippingDataImport />} />}
              />

              <Route
                path={PAGE_PATH_NAME.SHIPPING_DATA_IMPORT_DETAILS}
                element={
                  <PrivateRoute element={<ShippingDataImportDetails />} />
                }
              />
              <Route
                path={PAGE_PATH_NAME.NOTIFICATION_MANAGEMENT}
                element={<PrivateRoute element={<NotificationHistoryList />} />}
              />
              <Route
                path={PAGE_PATH_NAME.NOTIFICATION_CREATE}
                element={<PrivateRoute element={<NotificationCreate />} />}
              />
              <Route
                path={PAGE_PATH_NAME.NOTIFICATION_DETAILS}
                element={<PrivateRoute element={<NotificationEdit />} />}
              />
              <Route
                path={PAGE_PATH_NAME.DEVICE_URL_MAPPING_LIST}
                element={<PrivateRoute element={<DeviceUrlMappingList />} />}
              />
              <Route
                path={PAGE_PATH_NAME.EXPORT_CSV_TO_SFDC}
                element={<PrivateRoute element={<ExportCSVtoSFDC />} />}
              />
              <Route
                path={PAGE_PATH_NAME.REGION_MAPPING_LIST}
                element={<PrivateRoute element={<RegionMappingList />} />}
              />
              {/* <Route
                path={PAGE_PATH_NAME.NOTIFICATION_MANAGEMENT}
                element={<PrivateRoute element={<NotificationHistoryList />} />}
              />
              <Route
                path={PAGE_PATH_NAME.NOTIFICATION_CREATE}
                element={<PrivateRoute element={<NotificationCreate />} />}
              />
              <Route
                path={PAGE_PATH_NAME.NOTIFICATION_DETAILS}
                element={<PrivateRoute element={<NotificationEdit />} />}
              />
               <Route
                path={PAGE_PATH_NAME.EULA_MANAGEMENT}
                element={<PrivateRoute element={<EulaManagement />} />}
              />
              /> */}
            </Routes>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
      <ToastContainer
        hideProgressBar
        className={classes.customToastify}
        closeButton={CloseButton}
        theme='colored'
        position='top-center'
        autoClose={5000}
      />
    </div>
  )
}
export default App
