/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-unused-expressions */
import React from 'react'
import { ReactComponent as SortUpIcon } from '../../../../images/icon/sort-up.svg'
import { ReactComponent as SortDownIcon } from '../../../../images/icon/sort-down.svg'
import { ReactComponent as SortUpSelectedIcon } from '../../../../images/icon/sort-up-selected.svg'
import { ReactComponent as SortDownSelectedIcon } from '../../../../images/icon/sort-down-selected.svg'
import {
  DIRECTION_TYPE,
} from '../../../../types/datatable'
import { useStyles } from '../style'
import { ITableHeaderProps } from '../../../../types/table'

const THeader: React.FC<ITableHeaderProps> = (({ columnProperties, clickSortButton, sortConfig, gap, pageSrc }) => {
  const classes = useStyles()

  return (
    <div>
      <div 
        style={{ gap: gap || '42px' }}
        className={`${classes.tableHeader
        } ${classes.tableHeaderJustifyBetween}`}>
        {
          columnProperties?.map((element) => (
          <div className='headerItem'
            key={element.key}
            style={{
              display: "flex",
              alignItems: "center",
              width: `${element.colWidth}px`,
              maxWidth: `${element.colMaxWidth}px`,
              minWidth: `${element.colWidth}px`,
            }}
          >
            <div className={classes.tableHeaderTitle}>
              <div
                className={
                  pageSrc === 'eula' || !element?.isSort
                    ? classes.tableHeaderTitleTextWithNoHover
                    : classes.tableHeaderTitleText
                }
                aria-hidden
                onClick={() => {
                  element.isSort ? clickSortButton(element.key) : null
                }}
              >
                {element.name}
              </div>
              <div
                className={classes.tableHeaderIcon}
                aria-hidden
                onClick={() => {
                  element.isSort ? clickSortButton(element.key) : null
                }}
              >
                { 
                    element.isSort &&
                  sortConfig.key === element.key &&
                  sortConfig.direction === DIRECTION_TYPE.ascending && (
                    <div className={classes.tableHeaderIconContainer}>
                      <SortUpSelectedIcon />
                      <SortDownIcon />
                    </div>
                  )}
                {
                    element.isSort &&
                  sortConfig.key === element.key &&
                  sortConfig.direction ===
                    DIRECTION_TYPE.descending && (
                    <div className={classes.tableHeaderIconContainer}>
                      <SortUpIcon />
                      <SortDownSelectedIcon />
                    </div>
                  )}
                {
                    element.isSort &&
                  (sortConfig.key !== element.key ||
                    sortConfig.direction === DIRECTION_TYPE.none) && (
                    <div className={classes.tableHeaderIconContainer}>
                      <SortUpIcon />
                      <SortDownIcon />
                    </div>
                  )}
              </div>
            </div>
          </div>
        ))
        }
      </div>
    </div>
  )
})

export default THeader
