import { ITableProps } from '../../../types/_table'
import { useStyles } from '../../../themes'

const Table: React.FC<ITableProps> = ({
  type = 'single-line',
  tableWidth,
  children
}): JSX.Element => {
  const classes = useStyles()

  return (
    <div
      className={`${classes.dataTable} ${
        type === 'multi-line' ? classes.dataTableMulti : ''
      }`}
      style={{ minWidth: tableWidth??"0" }}
    >
      {children}
    </div>
  )
}

export default Table
