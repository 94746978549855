/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import * as Material from '@mui/material'
import { makeStyles } from '@mui/styles'
import CustomTooltip from '../Tooltip'
// import { ptColors } from '../../../themes/settings/colors';

type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'success'
  | 'danger'
  | 'dark'
  | 'info'
  | 'submit'
  | 'gray'

type ButtonProps = { color?: ColorTypes } & Omit<Material.ButtonProps, 'color'>

export type PTButtonProps = ButtonProps & {
  width?: string
  text?: string | number
  onClick?: (event: React.MouseEvent) => void
  className?: string
  color?: ColorTypes
  bgColor?: string
  borderColor?: string | null
  fontSize?: string | number
  alignItems?: string
  whiteSpace?:
    | 'pre'
    | 'normal'
    | 'nowrap'
    | '-moz-pre-wrap'
    | 'break-spaces'
    | 'pre-line'
    | 'pre-wrap'
  overflow?: string
  textOverflow?: string
  display?: string
  isTooltip?: boolean
}

const StatusButton: React.FC<PTButtonProps> = (props) => {
  const {
    width,
    display,
    color,
    text,
    bgColor,
    borderColor,
    children,
    fontSize,
    alignItems,
    whiteSpace,
    overflow,
    textOverflow,
    isTooltip,
    ...rest
  } = props

  const useStyles = makeStyles(() => ({
    customStatusBtn: {
      backgroundColor: `${bgColor || '#fff'}`,
      border: `1px solid ${borderColor || 'none'}`,
      color: `${borderColor || '#fff'}`,
      borderRadius: 16,
      height: 25,
      fontSize: fontSize ?? 12,
      alignItems: alignItems ?? 'center',
      display: display ?? 'flex',
      justifyContent: 'center',
      width: width ?? 'fit-content',
      padding: '0px 10px',
      fontWeight: 400,
      fontFamily: "'Noto Sans JP', sans-serif",
      textTransform: 'capitalize',
      lineHeight: '25px',
      maxHeight: '25px',
      maxWidth: '100%',
      whiteSpace: whiteSpace ?? 'normal',
      overflow: overflow ?? '',
      textOverflow: textOverflow ?? '',
    }
  }))

  const classes = useStyles({ color })

  return (
    <>
      {isTooltip ? (
        <CustomTooltip toolTipTitle={text}>
          <div
            // {...rest}
            className={`${classes.customStatusBtn} ${color || ''} ${
              rest.className ?? ''
            } `}
          >
            {text}
            {children}
          </div>
        </CustomTooltip>
      ) : (
        <div
          // {...rest}
          className={`${classes.customStatusBtn} ${color || ''} ${
            rest.className ?? ''
          } `}
        >
          {text}
          {children}
        </div>
      )}
    </>
  )
}

export default StatusButton
