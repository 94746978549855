import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { Box, DialogTitle, IconButton, Skeleton } from '@mui/material'
import { CustomDialog } from '../AddUser/style'
import { useStyles } from '../../Atoms/Dialog'
import useDeviceUrlMappingStyles from './style'
import { DialogTitleProps } from '../AddUser'
import { ReactComponent as CloseIcon } from '../../../images/icon/close-gray.svg'
import Loader from '../../Atoms/Loader/Loader'

const CustomDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles()
  const { children, onClose } = props

  return (
    <DialogTitle sx={{ fontSize: '18px', fontWeight: 'bold' }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          className={classes.iconBtn}
          sx={{ top: 'unset !important', right: '30px !important' }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export interface IDeviceUrlMappingFormProps {
  handleClose: () => void
  children?: ReactNode
  open: boolean
  title: string
  loading?: boolean
}

const DeviceUrlMappingForm: FC<
  IDeviceUrlMappingFormProps &
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ handleClose, children, open, title, loading, ...props }) => {
  const classes = useDeviceUrlMappingStyles()

  return (
    <CustomDialog
      sx={{ width: '644px', margin: '0 auto' }}
      onClose={() => handleClose()}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      {loading && (
        <Box className={classes.loaderParent}>
          <Loader colorCode='#939598' />
        </Box>
      )}
      {title === '' ? (
        <Skeleton />
      ) : (
        <CustomDialogTitle
          onClose={() => handleClose()}
          id='customized-dialog-title'
        >
          {title}
        </CustomDialogTitle>
      )}
      {children}
    </CustomDialog>
  )
}

export default DeviceUrlMappingForm
