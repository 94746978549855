/* eslint-disable arrow-body-style */
import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import { ITableCell } from '../../../../types/_table'

const TCell: React.FC<
  ITableCell & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ colMaxWidth, colWidth, children, isReloading, ...props }) => {
  return (
    <div
      style={{
        ...props.style,
        width: `${colWidth}px`,
        maxWidth: `${colMaxWidth}px`,
        minWidth: `${colWidth}px`,
        opacity: isReloading ? '0' : '1',
        minHeight: '30px',
        lineHeight: '30px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {children}
    </div>
  )
}

export default TCell
