/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import {
  Box,
  ClickAwayListener,
  Skeleton,
  Typography,
  Popper
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import PageTemplate from '../PageTemplate'
import { useCreateNotificationStyles } from '../NotificationCreate/style'
import { ReactComponent as CheckBoxIcon } from '../../../images/icon/checkbox.svg'
import { ReactComponent as CheckedCheckBox } from '../../../images/icon/checked_checkbox.svg'
import { ReactComponent as ExclamatoryIcon } from '../../../images/icon/exclamatory.svg'
import CustomSelect from '../../Atoms/CustomSelect'
import { ReactComponent as PreviewIcon } from '../../../images/icon/Preview.svg'
import CustomInput from '../../Atoms/Inputs'
import Datepicker from '../../Atoms/Datepicker'
import TextArea from '../../Atoms/TextArea'
import BellPreviewDialog from '../../Modecules/BellPreview'
import EmailPreviewDialog from '../../Modecules/EmailPreview'
import {
  useLazyGetNotificationbyIdQuery,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation
} from '../../../state/features/notifications/notificationSlice'
import Button from '../../Atoms/Buttons'
import { ReactComponent as DeleteIcon } from '../../../images/icon/delete.svg'
import CommonDialog from '../../Atoms/Dialog'
import { ReactComponent as DeleteIconModal } from '../../../images/icon/modal-delete.svg'
import { ReactComponent as AbandonIconModal } from '../../../images/icon/abandon.svg'
import { PAGE_PATH_NAME } from '../../../constants/constants'
import { OptionsType } from '../../../types/common'
import { ICustomError } from '../../../state/api/apiSlice'
import RegionFilter from '../../Modecules/RegionFilter'
import { serverRegionType } from '../../../state/features/serverRegion/serverRegionSlice'

export type NotificationEditType = {
  notification_type: string
  destination_type: string
  recipient: string
  schedule_status: string
  scheduler_start_date?: string
  publication_date?: string
  mail_subject?: string
  mail_template?: string
  email_body?: string
  notification_title?: string
  notification_detail?: string
}

interface INotificationEditForm {
  mailPreview: boolean
  bellPreview: boolean
  schedule: {
    state: OptionsType
    scheduleDate: string
    publicationDate: string
  }
  notificationType: OptionsType
  mailTemplate: OptionsType
  destinationType: {
    mail: boolean
    bell: boolean
  }
  recipient: OptionsType
  serverRegion: number
}

export const initialNotificationEditData: NotificationEditType = {
  notification_type: '',
  destination_type: 'bell',
  recipient: '',
  schedule_status: 'yes',
  mail_subject: '',
  mail_template: '',
  email_body: '',
  notification_title: '',
  notification_detail: ''
}

interface IErrorResponse {
  data: {
    errors:
      | {
          param: string
          message: string
        }[]
      | string
    result_code: string
    status: number
    success: boolean
    message: string
  }
  status: number
}

const options = {
  scheduleOptions: [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    }
  ],
  notificationTypeOptions: [
    {
      label: 'Announcement',
      value: 'Announcement'
    }
  ],
  mailTemplateOptions: [
    {
      label: 'Notification Mail Template',
      value: 'notification_mail_template'
    }
  ],
  recipientOptions: [
    {
      label: 'All Users',
      value: 'ALL'
    },
    {
      label: 'Admin',
      value: 'ADMIN'
    }
  ]
}

const NotificationEdit = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [notificationId, setNotificationId] = useState<number | null>(null)
  const [notificationServerRegionId, setNotificationServerRegionId] = useState<
    number | null
  >(null)
  const classes = useCreateNotificationStyles()
  const [isAbandon, setIsAbandon] = useState<boolean>(false)

  // handle delete dropdown
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null)
  const isMenuOpen = Boolean(anchorEl)

  const deleteMenuOpen = (event: React.MouseEvent<Element>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const deleteMenuClose = () => {
    setAnchorEl(null)
  }

  const [selectedServerRegion, setSelectedServerRegion] = useState<
    serverRegionType[]
  >([])

  const menuId = isMenuOpen ? 'simple-popper' : undefined

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    getValues
  } = useForm()

  const initialDeleteData = {
    state: false,
    notification_id: 0,
    delete_flag: false,
    abandon_flag: false
  }

  const [deleteNotificationModal, setDeleteNotificationModal] = useState<{
    state: boolean
    notification_id: number
    delete_flag: boolean
    abandon_flag: boolean
  }>(initialDeleteData)

  const [
    deleteNotification,
    {
      data: deleteNotificationData,
      isSuccess: deleteNotificationSuccess,
      isError: deleteNotificationIsError,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error: deleteNotificationError
    }
  ] = useDeleteNotificationMutation()

  const [formValid, setFormValid] = useState<boolean>(false)

  const [
    trigger,
    {
      data: notificationData,
      isLoading: notificationDataIsLoading,
      isError: notificationError
    }
  ] = useLazyGetNotificationbyIdQuery()

  const [
    updateNotification,
    {
      data: updatedData,
      isSuccess: updateSuccess,
      isError: updateIsError,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error: updateError
    }
  ] = useUpdateNotificationMutation()

  const [isMailPreviewed, setIsMailPreviewed] = useState<boolean>(false)
  const [isBellPreviewed, setIsBellPreviewed] = useState<boolean>(false)

  const [scheduleDate, setScheduleDate] = useState<Date | null>(null)
  const [publicationDate, setPublicationDate] = useState<Date | null>(null)

  // const [scheduleDateErrorMessage, setScheduleDateErrorMessage] =
  //   useState<string>('')
  // const [publicationDateErrorMessage, setPublicationDateErrorMessage] =
  //   useState<string>('')

  const handelConfirmDelete = async () => {
    await deleteNotification({
      notification_id: notificationId,
      delete_flag: true,
      abandon_flag: false,
      server_region_list: [notificationServerRegionId]
    })
    setDeleteNotificationModal(initialDeleteData)
  }

  const handelConfirmAbandon = async () => {
    await deleteNotification({
      notification_id: notificationId,
      delete_flag: false,
      abandon_flag: true,
      server_region_list: [notificationServerRegionId]
    })
    setDeleteNotificationModal(initialDeleteData)
  }

  useEffect(() => {
    if (deleteNotificationSuccess) {
      navigate(PAGE_PATH_NAME.NOTIFICATION_MANAGEMENT)
      if (isAbandon) {
        toast.success(deleteNotificationData?.data?.message, {
          style: { background: '#FF6712' }
        })
      } else {
        toast.success(deleteNotificationData?.data?.message)
      }
    }
    if (deleteNotificationIsError) {
      deleteNotificationError as IErrorResponse
      if ('data' in deleteNotificationError) {
        toast.error((deleteNotificationError as ICustomError).data.message)
      }
    }
  }, [
    deleteNotificationSuccess,
    deleteNotificationIsError,
    deleteNotificationError,
    navigate,
    deleteNotificationData?.data?.message,
    isAbandon
  ])

  useEffect(() => {
    const { notification_detail_data } = location.state as {
      notification_detail_data: string
    }
    const notification_id_data_string = notification_detail_data.split('/')[0]
    const notification_id_data = parseInt(notification_id_data_string, 10)
    const server_region_id_data = notification_detail_data.split('/')[1]

    if (notification_id_data && server_region_id_data) {
      let server_region_id_number
      if (server_region_id_data === 'US') {
        server_region_id_number = 1
      } else if (server_region_id_data === 'JP') {
        server_region_id_number = 2
      } else if (server_region_id_data === 'EU') {
        server_region_id_number = 3
      } else {
        server_region_id_number = 0
      }

      setNotificationId(notification_id_data)
      setNotificationServerRegionId(server_region_id_number)
      void trigger({
        notification_id: notification_id_data,
        server_region_id: server_region_id_number
      })
    }
  }, [location.state, trigger])

  const [selectedOptions, setSelectedOptions] = useState<INotificationEditForm>(
    {
      mailPreview: false,
      bellPreview: false,
      schedule: {
        state: {
          label: 'No',
          value: 'no'
        },
        scheduleDate: '',
        publicationDate: ''
      },
      notificationType: {
        label: 'Announcement',
        value: 'Announcement'
      },
      mailTemplate: {
        label: 'Notification Mail Template',
        value: 'notification_mail_template'
      },
      destinationType: {
        mail: false,
        bell: false
      },
      recipient: {
        label: 'All Users',
        value: 'ALL'
      },
      serverRegion: -1
    }
  )

  const watching = watch([
    'mail_subject',
    'mail_body',
    'bell_message_title',
    'bell_message_detail'
  ])

  useEffect(() => {
    if (
      selectedOptions.destinationType.mail &&
      selectedOptions.destinationType.bell
    ) {
      if (
        getValues('mail_subject') &&
        getValues('mail_body') &&
        getValues('bell_message_title') &&
        getValues('bell_message_detail') &&
        isMailPreviewed &&
        isBellPreviewed
      ) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
    } else if (
      !selectedOptions.destinationType.mail &&
      selectedOptions.destinationType.bell
    ) {
      if (
        getValues('bell_message_title') &&
        getValues('bell_message_detail') &&
        isBellPreviewed
      ) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
    } else if (
      selectedOptions.destinationType.mail &&
      !selectedOptions.destinationType.bell
    ) {
      if (
        getValues('mail_subject') &&
        getValues('mail_body') &&
        isMailPreviewed
      ) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
    } else {
      setFormValid(false)
    }
  }, [getValues, selectedOptions, watching, isBellPreviewed, isMailPreviewed])

  useEffect(() => {
    if (updateSuccess) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      toast.success(updatedData?.data.message)
      navigate('/notifications')
    }
    if (updateIsError && updateError && 'data' in updateError) {
      toast.error((updateError as ICustomError).data.message)
    }
  }, [updatedData, updateSuccess, updateIsError, updateError, navigate])

  // Data setter
  useEffect(() => {
    if (notificationData?.data) {
      if (notificationData?.data.destination_type === 'Both') {
        setSelectedOptions((prevState) => ({
          ...prevState,
          destinationType: {
            ...prevState.destinationType,
            mail: true,
            bell: true
          }
        }))
      } else if (notificationData?.data.destination_type === 'Mail') {
        setSelectedOptions((prevState) => ({
          ...prevState,
          destinationType: { ...prevState.destinationType, mail: true }
        }))
      } else if (notificationData?.data.destination_type === 'Bell') {
        setSelectedOptions((prevState) => ({
          ...prevState,
          destinationType: { ...prevState.destinationType, bell: true }
        }))
      }

      if (notificationData?.data.is_scheduled) {
        setSelectedOptions((prevState) => ({
          ...prevState,
          schedule: {
            ...prevState.schedule,
            state: {
              ...prevState.schedule.state,
              value: notificationData?.data.is_scheduled ? 'yes' : 'no',
              label: notificationData?.data.is_scheduled ? 'Yes' : 'No'
            }
          }
        }))
      }

      if (notificationData?.data.scheduler_start_date) {
        const date = new Date(notificationData?.data.scheduler_start_date)
        const offset = date.getTimezoneOffset()
        const local = new Date(date.getTime() - offset * 60000)
        setScheduleDate(local)
      }

      if (notificationData?.data.scheduler_end_date) {
        const date = new Date(notificationData?.data.scheduler_end_date)
        const offset = date.getTimezoneOffset()
        const local = new Date(date.getTime() - offset * 60000)
        setPublicationDate(local)
      }

      if (notificationData?.data.mail_subject) {
        register('mail_subject', { value: notificationData?.data.mail_subject })
      }

      if (notificationData?.data.mail_body) {
        register('mail_body', {
          value: notificationData?.data.mail_body
        })
      }

      if (notificationData?.data.bell_message_title) {
        register('bell_message_title', {
          value: notificationData?.data.bell_message_title
        })
      }

      if (notificationData?.data.bell_message_detail) {
        register('bell_message_detail', {
          value: notificationData?.data.bell_message_detail
        })
      }

      if (notificationData?.data.user_type) {
        if (notificationData?.data.user_type === 'ALL') {
          setSelectedOptions((prevState) => ({
            ...prevState,
            recipient: options.recipientOptions[0]
          }))
        } else {
          setSelectedOptions((prevState) => ({
            ...prevState,
            recipient: options.recipientOptions[1]
          }))
        }
      }

      if (
        notificationData?.data.scheduler_start_date &&
        notificationData?.data.scheduler_end_date
      ) {
        setSelectedOptions((prevState) => ({
          ...prevState,
          schedule: {
            ...prevState.schedule,
            state: {
              ...prevState.schedule.state,
              value: 'yes',
              label: 'Yes'
            }
          }
        }))
      }
    }

    if (notificationData?.data.server_region && notificationServerRegionId) {
      setSelectedOptions((prevState) => ({
        ...prevState,
        serverRegion: notificationServerRegionId
      }))
    }

    if (notificationError) {
      toast.error('Notification not found')
      navigate(PAGE_PATH_NAME.NOTIFICATION_MANAGEMENT)
    }
  }, [
    notificationData,
    notificationError,
    register,
    navigate,
    notificationServerRegionId
  ])

  const getNotificationType = (): string => {
    if (
      selectedOptions.destinationType.mail &&
      selectedOptions.destinationType.bell
    ) {
      return 'Both'
    }
    if (selectedOptions.destinationType.mail) {
      return 'Mail'
    }
    if (selectedOptions.destinationType.bell) {
      return 'Bell'
    }

    return ''
  }

  const getFormattedDate = (dateObj: Date) => {
    const years = dateObj.getFullYear()
    const months =
      dateObj.getMonth() + 1 < 10
        ? `0${String(dateObj.getMonth() + 1)}`
        : String(dateObj.getMonth() + 1)
    const date =
      dateObj.getDate() < 10
        ? `0${String(dateObj.getDate())}`
        : dateObj.getDate()
    const hours =
      dateObj.getHours() < 10
        ? `0${String(dateObj.getHours())}`
        : dateObj.getHours()
    const minutes =
      dateObj.getMinutes() < 10
        ? `0${String(dateObj.getMinutes())}`
        : dateObj.getMinutes()
    const seconds =
      dateObj.getSeconds() < 10
        ? `0${String(dateObj.getSeconds())}`
        : dateObj.getSeconds()

    return `${years}-${months}-${date} ${hours}:${minutes}:${seconds}.000`
  }

  const convertDateToLocal = (dateValue: Date) =>
    new Date(
      new Date(dateValue).getTime() -
        new Date(dateValue).getTimezoneOffset() * 60000
    )

  const onSubmit = (data: { [key: string]: string }, flag: string) => {
    const dataBody: Partial<{
      notification_id: number
      user_type: string
      destination_type: string
      bell_message_title: string
      bell_message_detail: string
      mail_subject: string
      mail_body: string
      scheduler_start_date: string
      scheduler_end_date: string
      notification_status: string
      is_scheduled: boolean
      server_region_list: number[]
    }> = {}
    if (
      !selectedOptions.destinationType.bell &&
      !selectedOptions.destinationType.mail
    ) {
      toast.error('Notification method cannot be empty')

      return
    }
    if (
      (selectedOptions.schedule.state.value === 'yes' && !scheduleDate) ||
      (selectedOptions.schedule.state.value === 'yes' && !publicationDate)
    ) {
      toast.error('Please select a time range for schedule')

      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      publicationDate &&
      scheduleDate.getTime() > publicationDate.getTime()
    ) {
      setPublicationDate(null)
      toast.error(
        'Schedule date and time cannot be after publication date and time'
      )

      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      publicationDate &&
      scheduleDate.getTime() === publicationDate.getTime()
    ) {
      setPublicationDate(null)
      toast.error(
        'Schedule date and time and publication date and time cannot be the same'
      )

      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      scheduleDate.getTime() < new Date().getTime()
    ) {
      setScheduleDate(null)
      toast.error('Schedule date cannot be before the current date and time')

      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      scheduleDate.getTime() < new Date().getTime() &&
      selectedOptions.schedule.state.value === 'yes' &&
      publicationDate &&
      publicationDate.getTime() < new Date().getTime()
    ) {
      setScheduleDate(null)
      toast.error('Schedule date cannot be before the current date and time')

      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      publicationDate &&
      publicationDate.getTime() < new Date().getTime()
    ) {
      setPublicationDate(null)
      toast.error('Publication date cannot be before the current date and time')

      return
    }

    const serverRegionList = [{
      id: 1,
      server_region_id: 1,
      server_region_name: 'US'
    },
    {
      id: 2,
      server_region_id: 2,
      server_region_name: 'JP'
    },
    {
      id: 3,
      server_region_id: 3,
      server_region_name: 'EU'
    }]

    dataBody.server_region_list = [serverRegionList.filter(({ server_region_name })=>server_region_name===notificationData?.data.server_region)[0].server_region_id]

    dataBody.notification_id = notificationId as number
    dataBody.destination_type = getNotificationType()

    dataBody.notification_status = flag
    dataBody.is_scheduled =
      // eslint-disable-next-line no-unneeded-ternary
      selectedOptions.schedule.state.value === 'yes' ? true : false
    if (selectedOptions.destinationType.mail) {
      dataBody.mail_subject = (getValues('mail_subject') as string).trim()
      dataBody.mail_body = (getValues('mail_body') as string).trim()
    }

    if (selectedOptions.destinationType.bell) {
      dataBody.bell_message_title = (
        getValues('bell_message_title') as string
      ).trim()
      dataBody.bell_message_detail = (
        getValues('bell_message_detail') as string
      ).trim()
    }

    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      publicationDate
    ) {
      dataBody.scheduler_start_date = getFormattedDate(
        new Date(
          scheduleDate.getTime() +
            new Date(scheduleDate).getTimezoneOffset() * 60000
        )
      )
      dataBody.scheduler_end_date = getFormattedDate(
        new Date(
          publicationDate.getTime() +
            new Date(publicationDate).getTimezoneOffset() * 60000
        )
      )
    }

    dataBody.user_type = selectedOptions.recipient.value
    void updateNotification(dataBody)
  }

  const getNotificationTypeDisabled = (): boolean => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }
    if (options.notificationTypeOptions.length <= 1) {
      return true
    }

    return false
  }

  const getRecipientDisabled = (): boolean => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }
    if (options.recipientOptions.length <= 1) {
      return true
    }

    return false
  }

  const getMailCheckboxDisabled = (): boolean => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }

    return false
  }

  const getBellCheckboxDisabled = (): boolean => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }

    return false
  }

  const getMailSubjectDisabled = (): boolean => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }

    return false
  }

  const getMailTemplateDisabled = (): boolean => {
    if (options.mailTemplateOptions.length <= 1) {
      return true
    }
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }

    return false
  }

  const getScheduleInformationDisabled = () => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }
    // if(selectedOptions.schedule.state.value === 'no')
    if (
      (selectedOptions.destinationType.mail &&
        !selectedOptions.destinationType.bell) ||
      (!selectedOptions.destinationType.mail &&
        !selectedOptions.destinationType.bell)
    ) {
      return true
    }

    return false
  }

  const getMailBodyDisabled = () => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }

    return false
  }

  const getBellTitleDisabled = () => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }

    return false
  }

  const getBellDetailDisabled = () => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }

    return false
  }

  const getDeleteButtonEnabled = () => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return false
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return false
    }

    return true
  }

  const getSubmitButtonDisabled = () => {
    if (notificationData?.data.notification_status === 'POSTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }
    if (!formValid) {
      return true
    }

    return false
  }

  const getAbandonButtonDisabled = () => {
    if (notificationData?.data.notification_status === 'DRAFTED') {
      return true
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }
    if (notificationData?.data.notification_status === 'SCHEDULED') {
      return true
    }
    if (notificationData?.data.notification_status === 'POSTED') {
      if (notificationData?.data.destination_type === 'Mail') {
        return true
      }
      if (
        notificationData?.data.destination_type === 'Bell' ||
        notificationData?.data.destination_type === 'Both'
      ) {
        if (
          notificationData?.data.is_scheduled === true &&
          convertDateToLocal(
            notificationData?.data.scheduler_end_date as Date
          ).getTime() < new Date().getTime()
        ) {
          return true
        }

        return false
      }

      return false
    }

    return false
  }

  const getDraftedButtonDisabled = () => {
    if (notificationData?.data.notification_status === 'DRAFTED') {
      return false
    }
    if (notificationData?.data.notification_status === 'ABANDONED') {
      return true
    }

    return true
  }

  return (
    <PageTemplate>
      <Box className={classes.actionBlock}>
        {getDeleteButtonEnabled() ? (
          !notificationDataIsLoading ? (
            <Box sx={{ position: 'relative' }}>
              <Button
                type='button'
                className={`${classes.deleteBtn}`}
                onClick={deleteMenuOpen}
                aria-controls={menuId}
                aria-describedby={menuId}
                aria-haspopup='true'
              >
                <Typography className={classes.dot} />
              </Button>
              <Popper
                placement='bottom-end'
                id={menuId}
                open={isMenuOpen}
                anchorEl={anchorEl}
                popperOptions={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-2, -37]
                      }
                    }
                  ]
                }}
              >
                <ClickAwayListener onClickAway={deleteMenuClose}>
                  <Box
                    className={classes.deleteAction}
                    onClick={() => {
                      setDeleteNotificationModal((prev) => ({
                        ...prev,
                        state: true
                      }))
                      setIsAbandon(false)
                    }}
                  >
                    <DeleteIcon />
                    <Typography className={classes.deleteText}>
                      {t('notificationDetails.delete')}
                    </Typography>
                  </Box>
                </ClickAwayListener>
              </Popper>
            </Box>
          ) : (
            <Skeleton width='38px' height='38px' />
          )
        ) : null}
        {!notificationDataIsLoading ? (
          <Button
            disabled={getAbandonButtonDisabled()}
            onClick={() => {
              setIsAbandon(true)
              setDeleteNotificationModal((prev) => ({
                ...prev,
                state: true
              }))
            }}
            className={classes.draftBtn}
          >
            {t('notificationDetails.abandon')}
          </Button>
        ) : (
          <Skeleton width='101px' height='38px' />
        )}
        {!notificationDataIsLoading ? (
          <Button
            disabled={getDraftedButtonDisabled()}
            className={classes.draftBtn}
            onClick={handleSubmit((formData) => onSubmit(formData, 'DRAFTED'))}
          >
            {t('notificationDetails.saveAsDraft')}
          </Button>
        ) : (
          <Skeleton width='101px' height='38px' />
        )}
        {!notificationDataIsLoading ? (
          <Tooltip
            arrow
            placement='top'
            title={<p>{t('notificationDetails.requirePreview')}</p>}
            sx={{ marginTop: 0 }}
            componentsProps={{
              tooltip: {
                sx: {
                  display: !formValid ? 'none' : '',
                  width: '160px',
                  padding: '6px 11px',
                  textAlign: 'center',
                  fontWeight: 400,
                  fontSize: '14px',
                  backgroundColor: '#37475A',
                  lineHeight: '17px'
                }
              }
            }}
          >
            <span>
              <Button
                color='primary'
                disabled={getSubmitButtonDisabled()}
                className={classes.submitBtn}
                onClick={handleSubmit((formData) =>
                  onSubmit(formData, 'SUBMITTED')
                )}
              >
                {t('notificationDetails.submit')}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Skeleton width='101px' height='38px' />
        )}
      </Box>
      <form>
        <Box className={classes.createNotificationContainer}>
          <Box className={classes.cardContainer}>
            <Box>
              <Typography className={classes.headerText}>
                Edit Notification
              </Typography>
              <Box>
                <Box className={classes.formBlock}>
                  <Typography className={classes.textTitle}>
                    Basic Information
                  </Typography>
                  <Box className={classes.formRow}>
                    <Box className={classes.formCol}>
                      <Box className={classes.formInput}>
                        {!notificationDataIsLoading ? (
                          <CustomSelect
                            disabled={getNotificationTypeDisabled()}
                            selectedOption={selectedOptions.notificationType}
                            optionChangeHandler={(option) => {
                              register('notification_type', {
                                value: option.value
                              })
                              setSelectedOptions((prevState) => ({
                                ...prevState,
                                notificationType: option
                              }))
                            }}
                            options={options.notificationTypeOptions}
                            labelText='Notification type'
                            required
                            defaultValue={options.notificationTypeOptions[0]}
                            name='notification_type'
                          />
                        ) : (
                          <>
                            <Skeleton />
                            <Skeleton sx={{ height: '38px' }} />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.formCol}>
                      <Box className={classes.formInput}>
                        {!notificationDataIsLoading ? (
                          <CustomSelect
                            disabled={getRecipientDisabled()}
                            selectedOption={selectedOptions.recipient}
                            optionChangeHandler={(option) => {
                              register('user_type', { value: option.value })
                              setSelectedOptions((prevState) => ({
                                ...prevState,
                                recipient: option
                              }))
                            }}
                            options={options.recipientOptions}
                            labelText='Recipient'
                            required
                            defaultValue={
                              // ?? Questionable
                              {
                                label: notificationData?.data
                                  .user_type as string,
                                value: notificationData?.data
                                  .user_type as string
                              }
                            }
                            name='recipient'
                          />
                        ) : (
                          <>
                            <Skeleton />
                            <Skeleton sx={{ height: '38px' }} />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.formCol}>
                      <Box className={classes.formInput}>
                        {!notificationDataIsLoading ? (
                          <>
                            <Typography className={classes.formLabel}>
                              Notification Method{' '}
                              <span className={classes.required}>*</span>
                            </Typography>
                            <FormGroup row className={classes.formGroup}>
                              <FormControlLabel
                                className={classes.checkBoxLabel}
                                control={
                                  <Checkbox
                                    disabled={getMailCheckboxDisabled()}
                                    className={classes.checkBoxInput}
                                    checked={
                                      selectedOptions.destinationType.mail
                                    }
                                    onChange={() => {
                                      setSelectedOptions((prevState) => ({
                                        ...prevState,
                                        destinationType: {
                                          ...prevState.destinationType,
                                          mail: !selectedOptions.destinationType
                                            .mail
                                        }
                                      }))
                                      // if (!selectedOptions.mailPreview) {
                                      //   setSelectedOptions((prevState) => ({
                                      //     ...prevState,
                                      //     mailSubject: '',
                                      //     mailBody: ''
                                      //   }))
                                      // }
                                    }}
                                    name='destination_type'
                                    value='mail'
                                    icon={
                                      <CheckBoxIcon
                                        style={{
                                          marginTop: '1px',
                                          marginRight: '-2px'
                                        }}
                                      />
                                    }
                                    checkedIcon={<CheckedCheckBox />}
                                  />
                                }
                                label='Mail'
                              />
                              <FormControlLabel
                                className={classes.checkBoxLabel}
                                control={
                                  <Checkbox
                                    disabled={getBellCheckboxDisabled()}
                                    className={classes.checkBoxInput}
                                    checked={
                                      selectedOptions.destinationType.bell
                                    }
                                    onChange={() => {
                                      setSelectedOptions((prevState) => ({
                                        ...prevState,
                                        destinationType: {
                                          ...prevState.destinationType,
                                          bell: !selectedOptions.destinationType
                                            .bell
                                        }
                                      }))
                                      if (
                                        selectedOptions.destinationType.bell
                                      ) {
                                        setSelectedOptions((prevState) => ({
                                          ...prevState,
                                          schedule: {
                                            ...prevState.schedule,
                                            state: {
                                              ...prevState.schedule.state,
                                              value: 'no',
                                              label: 'No'
                                            }
                                          }
                                        }))
                                        setScheduleDate(null)
                                        setPublicationDate(null)
                                      }
                                      // if (!selectedOptions.bellPreview) {
                                      //   setSelectedOptions((prevState) => ({
                                      //     ...prevState,
                                      //     mailSubject: '',
                                      //     mailBody: ''
                                      //   }))
                                      // }
                                    }}
                                    name='destination_type'
                                    value='bell'
                                    icon={
                                      <CheckBoxIcon
                                        style={{
                                          marginTop: '1px',
                                          marginRight: '-2px'
                                        }}
                                      />
                                    }
                                    checkedIcon={<CheckedCheckBox />}
                                  />
                                }
                                label='Bell'
                              />
                            </FormGroup>
                          </>
                        ) : (
                          <>
                            <Skeleton />
                            <Skeleton sx={{ height: '38px' }} />
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.formBlock}>
                  <Typography className={classes.textTitle}>
                    Schedule Information
                  </Typography>
                  <Box className={classes.formRow}>
                    <Box className={classes.formCol}>
                      <Box className={classes.formInput}>
                        {!notificationDataIsLoading ? (
                          <CustomSelect
                            disabled={getScheduleInformationDisabled()}
                            selectedOption={selectedOptions.schedule.state}
                            optionChangeHandler={(option) => {
                              if (selectedOptions.schedule.state) {
                                register('scheduler_start_date', {
                                  value: null
                                })
                                register('scheduler_end_date', {
                                  value: null
                                })
                              } else {
                                register('scheduler_start_date', {
                                  value: null
                                })
                                register('scheduler_end_date', { value: null })
                              }
                              setSelectedOptions((prevState) => ({
                                ...prevState,
                                schedule: {
                                  ...prevState.schedule,
                                  state: option
                                }
                              }))
                            }}
                            options={options.scheduleOptions}
                            labelText='Schedule'
                            required
                            defaultValue={options.scheduleOptions[0]}
                            name='schedule_status'
                          />
                        ) : (
                          <>
                            <Skeleton />
                            <Skeleton sx={{ height: '38px' }} />
                          </>
                        )}
                      </Box>
                    </Box>
                    {selectedOptions.schedule.state.value === 'yes' ? (
                      <>
                        <Box className={classes.formCol}>
                          <Box className={classes.formInput}>
                            <Typography className={classes.formLabel}>
                              Schedule Date{' '}
                              <span className={classes.required}>*</span>
                            </Typography>
                            <Datepicker
                              disabled={getScheduleInformationDisabled()}
                              value={scheduleDate}
                              onDateChange={(date) => {
                                setScheduleDate(date as Date)
                                if (publicationDate && date > publicationDate) {
                                  setPublicationDate(null)
                                }
                              }}
                              min={new Date()}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formCol}>
                          <Typography className={classes.formLabel}>
                            Publication Deadline{' '}
                            <span className={classes.required}>*</span>
                          </Typography>
                          <Datepicker
                            disabled={getScheduleInformationDisabled()}
                            value={publicationDate}
                            onDateChange={(date: Date) => {
                              setPublicationDate(date)
                            }}
                            min={new Date()}
                          />
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </Box>
                {!notificationDataIsLoading ? (
                  <Box className={classes.formBlock}>
                    <Typography className={classes.textTitle}>
                      Destination Regions
                    </Typography>
                    <Box className={classes.formRow}>
                      <Box className={classes.formCol}>
                        <Box className={classes.formInput}>
                          <Typography className={classes.formLabel}>
                            Server Regions{' '}
                            <span className={classes.required}>*</span>
                          </Typography>
                          <RegionFilter
                            setSelectedServerRegion={setSelectedServerRegion}
                            titleText=''
                            topMargin='0px'
                            width='604px'
                            isDisabled
                            serverRegionIds={notificationServerRegionId ?? -1}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Skeleton sx={{ height: '38px' }} />
                )}
              </Box>
            </Box>
            <Box>
              {selectedOptions.destinationType.mail ? (
                <Box className={classes.mailBlock} id='mail'>
                  <Box className={classes.headerBlock}>
                    <Typography className={classes.headerTitle}>
                      Mail Information
                    </Typography>
                    <button
                      disabled={
                        !!(
                          getValues('mail_subject') === '' ||
                          getValues('mail_body') === ''
                        )
                      }
                      type='button'
                      className={classes.previewBtn}
                      onClick={() => {
                        setIsMailPreviewed(true)
                        setSelectedOptions((prevState) => ({
                          ...prevState,
                          mailPreview: true
                        }))
                      }}
                    >
                      <span className={classes.previewText}>Preview</span>
                      <PreviewIcon />
                    </button>
                  </Box>

                  <Box className={`${classes.formInput} ${classes.mb13}`}>
                    <CustomInput
                      register={register}
                      registerField='mail_subject'
                      label='Mail Subject'
                      placeholder='Mail Subject'
                      errors={errors}
                      required
                      min={1}
                      max={256}
                      disabled={getMailSubjectDisabled()}
                    />
                  </Box>
                  <Box className={`${classes.formInput} ${classes.mb13}`}>
                    <CustomSelect
                      disabled={getMailTemplateDisabled()}
                      selectedOption={selectedOptions.mailTemplate}
                      optionChangeHandler={(option) => {
                        setSelectedOptions((prevState) => ({
                          ...prevState,
                          mailTemplate: option
                        }))
                      }}
                      options={options.mailTemplateOptions}
                      labelText='Mail Template'
                      required
                      defaultValue={options.mailTemplateOptions[0]}
                      name='mail_template'
                    />
                  </Box>
                  <Box>
                    <TextArea
                      disabled={getMailBodyDisabled()}
                      register={register}
                      registerField='mail_body'
                      label='Email Body'
                      placeholder='Type the message here...'
                      errors={errors}
                      required
                      min={1}
                      max={2000}
                      labelIcon={<ExclamatoryIcon />}
                      labelIconTooltipText={`${t(
                        'notificationDetails.importantMessage'
                      )}`}
                    />
                  </Box>
                </Box>
              ) : null}
              {selectedOptions.destinationType.bell ? (
                <Box className={classes.bellBlock} id='bell'>
                  <Box className={classes.headerBlock}>
                    <Typography className={classes.headerTitle}>
                      Bell Information
                    </Typography>
                    <button
                      disabled={
                        !(
                          getValues('bell_message_title') &&
                          getValues('bell_message_detail')
                        )
                      }
                      type='button'
                      className={classes.previewBtn}
                      onClick={() => {
                        setIsBellPreviewed(true)
                        setSelectedOptions((prevState) => ({
                          ...prevState,
                          bellPreview: true
                        }))
                      }}
                    >
                      <span className={classes.previewText}>Preview</span>
                      <PreviewIcon />
                    </button>
                  </Box>
                  <Box className={`${classes.formInput} ${classes.mb13}`}>
                    <CustomInput
                      disabled={getBellTitleDisabled()}
                      register={register}
                      registerField='bell_message_title'
                      label='Bell Title'
                      placeholder='Bell Title'
                      errors={errors}
                      required
                      min={1}
                      max={256}
                    />
                  </Box>
                  <Box>
                    <TextArea
                      disabled={getBellDetailDisabled()}
                      register={register}
                      registerField='bell_message_detail'
                      label='Notification Detail'
                      placeholder='Type the message here...'
                      errors={errors}
                      required
                      min={1}
                      max={2000}
                      labelIcon={<ExclamatoryIcon />}
                      labelIconTooltipText={`${t(
                        'notificationDetails.importantMessage'
                      )}`}
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </form>
      {selectedOptions.mailPreview ? (
        <EmailPreviewDialog
          date={`${new Date().getFullYear()}/${String(
            new Date().getMonth() + 1
          )}/${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`}
          title={getValues('mail_subject') as string}
          body={getValues('mail_body') as string}
          handleClose={() => {
            setSelectedOptions((prevState) => ({
              ...prevState,
              mailPreview: false
            }))
          }}
          open={selectedOptions.mailPreview}
        />
      ) : null}
      {selectedOptions.bellPreview ? (
        <BellPreviewDialog
          date={`${new Date().getFullYear()}/${String(
            new Date().getMonth() + 1
          )}/${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`}
          title={getValues('bell_message_title') as string}
          body={getValues('bell_message_detail') as string}
          handleClose={() => {
            setSelectedOptions((prevState) => ({
              ...prevState,
              bellPreview: false
            }))
          }}
          open={selectedOptions.bellPreview}
        />
      ) : null}
      <CommonDialog
        abandon={isAbandon}
        open={deleteNotificationModal.state}
        handleClose={() => {
          setDeleteNotificationModal(initialDeleteData)
        }}
        handleSubmit={isAbandon ? handelConfirmAbandon : handelConfirmDelete}
        icon={isAbandon ? AbandonIconModal : DeleteIconModal}
        title={`${
          isAbandon
            ? t('notificationDetails.abandonNotificationConfirmationMessage')
            : t('notificationDetails.deleteNotificationConfirmationMessage')
        }`}
        description={`${t('notificationDetails.deleteModalDescription')}`}
        cancelText={`${t('notificationDetails.cancel')}`}
        successText={`${
          isAbandon
            ? t('notificationDetails.abandon')
            : t('notificationDetails.delete')
        }`}
      />
    </PageTemplate>
  )
}

export default NotificationEdit
