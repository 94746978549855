/* eslint-disable react/require-default-props */
import React from 'react'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'
import { useStyles } from '../../../themes'
import FromErrorMessage from '../FormErrorToolTip'
import CustomTooltip from '../Tooltip'
import { ptColors } from '../../../themes/settings/_colors'

export interface FileInputProps {
  onChange: (file: File | null) => void
  errorText?: string
  isError?: boolean
  placeholder?: string
  acceptedFileTypes?: string
  fileNameText?: string
}
const FileInput = ({
  errorText,
  isError,
  onChange,
  placeholder,
  acceptedFileTypes,
  fileNameText
}: FileInputProps) => {
  const classes = useStyles()
  const [file, setFile] = React.useState<File | null>(null)
  const [fileName, setFileName] = React.useState<string | null>(null)
  const ref = React.useRef<HTMLInputElement>(null)

  // handle file
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.item(0)

    if (!selectedFile) {
      return null
    }

    const fileType = selectedFile.type.toLowerCase()
    const fileNameParts = selectedFile.name.split('.')
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()

    if (
      !(
        fileType === 'text/csv' ||
        (fileType === 'application/vnd.ms-excel' && fileExtension === 'csv')
      )
    ) {
      setFile(null)
      setFileName(null)
      toast.error('Please select a CSV file.')

      return null
    }

    return setFile(selectedFile)
  }

  React.useEffect(() => {
    if (fileNameText) {
      setFileName(fileNameText)
    } else {
      setFileName(null)
      if (ref.current) {
        ref.current.value = ''
      }
    }
  }, [fileNameText])

  React.useEffect(() => {
    if (file) {
      setFileName(file.name)
      onChange(file)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <FromErrorMessage message={errorText} isError={isError}>
      <Box
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={`${classes.fileInput} ${
          isError
            ? classes.fileInput_borderError
            : classes.fileInput_borderNormal
        }`}
      >
        <span
          className={`${classes.fileInput_placeholder} ${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            fileName && classes.fileInput_fileName
          }`}
        >
          <CustomTooltip
            color={`${fileName ? '' : ptColors.paleSky}`}
            toolTipTitle={fileName || placeholder}
            maxWidth={350}
          />
        </span>
        <span
          className={`${classes.fileInput_text} ${
            fileName ? classes.pos_relative : ''
          }`}
        >
          Browse Files
          <input
            type='file'
            className={classes.fileInput_input}
            accept={acceptedFileTypes}
            onChange={handleFile}
            ref={ref}
          />
        </span>
      </Box>
    </FromErrorMessage>
  )
}

export default FileInput
