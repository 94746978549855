/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { useStyles } from '../../../themes'
import SearchField from '../../Atoms/SearchInput'
import PageTemplate from '../PageTemplate'
import {
  PAGE_PATH_NAME,
  TABLE_ROW_NUMBER_PER_PAGE
} from '../../../constants/constants'
import { ReactComponent as Corporation } from '../../../images/icon/no-device.svg'
// import Table from '../../Templates/Table'
import Table from '../../Templates/_Table'
import THeader from '../../Modecules/Table/THeader'
import TBody from '../../Modecules/_Table/TBody'
import TRow from '../../Modecules/_Table/TRow'
import TCell from '../../Modecules/_Table/TCell'
import Loader from '../../Atoms/Loader/Loader'
import Pagination from '../../Modecules/Pagination/Pagination'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import usePagination from '../../../hooks/usePagination'
import {
  // IColumnProp,
  IColumnProps
} from '../../../types/table'
import StatusButton from '../../Atoms/StatusButton'
import { useLazyNotificationListQuery } from '../../../state/features/notifications/notificationSlice'
import Button from '../../Atoms/Buttons'
import { ReactComponent as AddIcon } from '../../../images/icon/addIcon.svg'
import MultiLineTooltip from '../../Atoms/Tooltip/MultiLineTooltip'
import { convertBlockUnderscoreTextIntoCamelCaseWithSpace } from '../../../utils/utility'
// import notificationsListDummy from '../../../mocks/data/notification-list'
import { ptColors } from '../../../themes/settings/_colors'
import { serverRegionType } from '../../../state/features/serverRegion/serverRegionSlice'
import RegionFilter from '../../Modecules/RegionFilter'

export type notificationDetailsStateProps = {
  notificationId: number
}

const NotificationHistoryList = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })

  const [selectedServerRegion, setSelectedServerRegion] = useState<
    serverRegionType[]
  >([])

  const [selectedServerRegionId, setSelectedServerRegionId] = useState<
    number[]
  >([])

  const [filterQuery, setFilterQuery] = useState({
    searchText: '',
    sortDirection: '',
    colName: '',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0,
    serverRegionIdList: selectedServerRegionId
  })

  useEffect(() => {
    const selectedIds = selectedServerRegion?.map(
      (region: serverRegionType) => region?.id
    )

    setSelectedServerRegionId(selectedIds)

    setFilterQuery((prev) => ({
      ...prev,
      serverRegionIdList: selectedIds
    }))
  }, [selectedServerRegion])

  const [
    trigger,
    {
      data: notificationHistoryList,
      isSuccess: notificationHistoryListIsSuccess,
      isFetching: notificationHistoryListIsFetching
    }
  ] = useLazyNotificationListQuery()

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [trigger, filterQuery])

  const sortFieldsNameReplace = (substr: string) => {
    if (substr === 'bell_message_title') {
      return 'bellMessageTitle'
    }
    if (substr === 'mail_subject') {
      return 'mailSubject'
    }
    if (substr === 'destination_info') {
      return 'destinationInfo'
    }
    if (substr === 'scheduler_start_date') {
      return 'schedulerStartDate'
    }
    if (substr === 'notified_dt') {
      return 'notifiedDt'
    }
    if (substr === 'scheduler_end_date') {
      return 'schedulerEndDate'
    }
    if (substr === 'server_region') {
      return 'serverRegion'
    }
    if (substr === 'status') {
      return 'status'
    }

    return ''
  }

  const {
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    notificationHistoryList
      ? notificationHistoryList?.data.total_notifications
      : 0
  )

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setCurrentPage(0)
  }, [filterQuery.searchText, setCurrentPage])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate.key === '' ? '' : sortConfigUpdate.direction,
      colName:
        sortConfigUpdate.direction === DIRECTION_TYPE.none
          ? ''
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }

  const columnProps: IColumnProps = [
    {
      key: 'bell_message_title',
      name: 'Bell Title',
      colWidth: 274,
      colMaxWidth: 274,
      isSort: true
    },
    {
      key: 'mail_subject',
      name: 'Mail Title',
      colWidth: 259,
      colMaxWidth: 259,
      isSort: true
    },
    {
      key: 'destination_info',
      name: 'Notification Method',
      colWidth: 133,
      colMaxWidth: 133,
      isSort: true
    },
    {
      key: 'scheduler_start_date',
      name: 'Schedule Date',
      colWidth: 118,
      colMaxWidth: 118,
      isSort: true
    },
    {
      key: 'notified_dt',
      name: 'Sent Date',
      colWidth: 118,
      colMaxWidth: 118,
      isSort: true
    },
    {
      key: 'scheduler_end_date',
      name: 'Publication Period',
      colWidth: 137,
      colMaxWidth: 137,
      isSort: true
    },
    {
      key: 'server_region',
      name: 'Server Region',
      colWidth: 106,
      colMaxWidth: 106,
      isSort: true
    },
    {
      key: 'status',
      name: 'Status',
      colWidth: 105,
      colMaxWidth: 105,
      isSort: true
    }
  ]

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'ABANDONED':
        return ptColors.pumpkinOrange
      case 'POSTED':
      case 'SUBMITTED':
        return ptColors.notification.statusGreen
      case 'SCHEDULED':
        return ptColors.notification.statusGrey
      case 'DRAFTED':
        return ptColors.notification.lostModeYellow
      default:
        return ''
    }
  }

  const getTimeTonze = (value: string | number | Date) => {
    const date = new Date(value)
    const offset = date.getTimezoneOffset()
    const local = new Date(date.getTime() - offset * 60000)
    
    return local
  }

  return (
    <PageTemplate>
      <div className={classes.searchBlock}>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <SearchField
            clickFilterSearch={(val, isChange) => {
              if (isChange) {
                setFilterQuery((prevState) => ({
                  ...prevState,
                  searchText: val
                }))
              }
            }}
            placeholder={`${t('notificationHistory.searchPlaceholderText')}`}
          />
          <RegionFilter setSelectedServerRegion={setSelectedServerRegion} />
        </Box>
        <Button
          className={classes.addNotificationBtn}
          color='primary'
          onClick={() => {
            navigate('/notifications/create-notification')
          }}
        >
          <AddIcon />
          {t('notificationHistory.addNew')}
        </Button>
      </div>
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('notificationHistory.headerTitle')}`} (
            {notificationHistoryList?.data?.total_notifications ?? 0})
          </Typography>
        </div>
        {notificationHistoryList?.data?.total_notifications === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <Corporation />
            <p className={classes.noFountText}>
              {t('notificationHistory.noNotificationsFound')}
            </p>
            <p className={classes.noAvailableText}>
              {t('notificationHistory.noItemsFound')}
            </p>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table tableWidth='1550px'>
                <THeader
                  clickSortButton={onClickSortButton}
                  sortConfig={sortConfig}
                  columnProperties={columnProps}
                />
                <TBody
                  isSuccess={notificationHistoryListIsSuccess}
                  columnProperties={columnProps}
                >
                  {notificationHistoryList?.data?.notifications.map(
                    (element, index) => (
                      <TRow
                        onClick={()=>{
                          element.status === "SUBMITTED" && toast.error("Please wait until this notification is posted");
                        }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        index={element.notification_id}
                        hyperlink={{
                          valid: element.status !== 'SUBMITTED',
                          path: PAGE_PATH_NAME.NOTIFICATION_DETAILS,
                          state: {
                            key: 'notification_detail_data',
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            value: `${element?.notification_id}/${element?.server_region}`
                          }
                        }}
                      >
                        <TCell
                          colMaxWidth={columnProps[0].colMaxWidth}
                          colWidth={columnProps[0].colWidth}
                          isReloading={notificationHistoryListIsFetching}
                          key='bell_message_title'
                        >
                          <MultiLineTooltip
                            toolTipTitle={element.bell_message_title ?? '-'}
                          />
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[1].colMaxWidth}
                          colWidth={columnProps[1].colWidth}
                          isReloading={notificationHistoryListIsFetching}
                          key='mail_subject'
                        >
                          <MultiLineTooltip
                            toolTipTitle={element.mail_subject ?? '-'}
                          />
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[2].colMaxWidth}
                          colWidth={columnProps[2].colWidth}
                          isReloading={notificationHistoryListIsFetching}
                          key='destination_info'
                        >
                          <MultiLineTooltip
                            toolTipTitle={element.destination_info ?? '-'}
                          />
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[3].colMaxWidth}
                          colWidth={columnProps[3].colWidth}
                          isReloading={notificationHistoryListIsFetching}
                          key='scheduler_start_date'
                        >
                          {element.scheduler_start_date
                            ? format(
                                getTimeTonze(element.scheduler_start_date),
                                'MMM d, yyyy  HH:mm'
                              )
                            : '-'}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[4].colMaxWidth}
                          colWidth={columnProps[4].colWidth}
                          isReloading={notificationHistoryListIsFetching}
                          key='notified_dt'
                        >
                          {element.notified_dt
                            ? format(getTimeTonze(element.notified_dt),'MMM d, yyyy  HH:mm')
                            : '-'}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[5].colMaxWidth}
                          colWidth={columnProps[5].colWidth}
                          isReloading={notificationHistoryListIsFetching}
                          key='scheduler_end_date'
                        >
                          {element.scheduler_end_date
                            ? format(getTimeTonze(element.scheduler_end_date),'MMM d, yyyy  HH:mm')
                            : '-'}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[6].colMaxWidth}
                          colWidth={columnProps[6].colWidth}
                          isReloading={notificationHistoryListIsFetching}
                          key='server_region'
                        >
                          {element.server_region ? element.server_region : '-'}
                        </TCell>
                        <TCell
                          colMaxWidth={columnProps[7].colMaxWidth}
                          colWidth={columnProps[7].colWidth}
                          isReloading={notificationHistoryListIsFetching}
                          key='status'
                        >
                          <StatusButton
                            bgColor={getStatusColor(element.status)}
                            text={convertBlockUnderscoreTextIntoCamelCaseWithSpace(
                              element.status
                            )}
                          />
                        </TCell>
                      </TRow>
                    )
                  )}
                </TBody>
              </Table>
            </SimpleBar>
            {notificationHistoryListIsFetching &&
            notificationHistoryListIsSuccess ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top:
                    notificationHistoryList?.data?.total_notifications === 1
                      ? '10%'
                      : notificationHistoryList?.data?.total_notifications === 2
                      ? '25%'
                      : '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode='#939598' />
              </Box>
            ) : null}
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  (notificationHistoryList
                    ? notificationHistoryList.data?.total_notifications
                    : 0) / rowsPerPage
                )}
                clickPrevPageButton={onClickPrevPage}
                clickNextPageButton={onClickNextPage}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={
                  (currentPage + 1) * rowsPerPage >
                  (notificationHistoryList
                    ? notificationHistoryList.data?.total_notifications
                    : 0)
                    ? notificationHistoryList
                      ? notificationHistoryList.data?.total_notifications
                      : 0
                    : (currentPage + 1) * rowsPerPage
                }
                totalDataRows={
                  notificationHistoryList
                    ? notificationHistoryList.data?.total_notifications
                    : 0
                }
              />
            </div>
          </Box>
        )}
      </Box>
    </PageTemplate>
  )
}

export default NotificationHistoryList
