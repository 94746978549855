/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { makeStyles } from '@mui/styles'
import { ptColors } from '../../../themes/settings/colors'

const useShippingImportStyles = makeStyles(() => ({

  cancelBtn: {
    color: '#232F3E',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #DDDDDD',
    height: '44px',
    padding: '10px 19px',
    borderRadius: '4px',
    margin: '0 0 0 7px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      border: '1px solid #D1D1D1',
      color: '#000'
    },
    '&:focus': {
      outline: '4px solid #CCEBF9',
      color: '#000'
    }
  },
  btnBox: {
    padding: '12px 0px 0 0px !important',
    // marginTop:'20px',
    borderTop : '1px solid #D9D9D9',
    display: 'flex',
    justifyContent: 'flex-end !important',
    alignItems: 'center',
    columnGap: '10px'
  },

  importBtn: {
    color: '#ffffff !important',
    fontWeight: '500',
    backgroundColor: '#007EB4',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #0078AB !important',
    height: '44px',
    padding: '10px 20px',
    borderRadius: '4px',
    margin: '0 0 0 7px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#006D9C !important',
      border: '1px solid #0078AB'
    },
    '& .MuiButton-startIcon': {
      marginLeft: '0',
      marginRight: '6px'
    },
    '&:focus': {
      outline: '5px solid #CCEBF9'
    }
  },

  showMoreBlock: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    background: 'none',
    border: 'none',
    padding: '2px 0',
    textTransform: 'none',
    '&:hover': {
      background: 'none'
    },
    '&.Mui-focusVisible .MuiTypography-root': {
      color: '#a90000 !important',
    },
    '&.Mui-focusVisible svg path': {
      stroke: '#a90000 !important',
    }
  },
  showMoreText: {
    fontSize: "13px",
    fontWeight: 400,
    color: `${ptColors.rossoCorsa}`,
    marginRight: 10,
  },


  shippingImportSimplebar: {
    minHeight: '220px',
    maxHeight: '500px',
    padding: '0 32px 13px',
  },


  topTextStyle: {
    color: `#232F3E`,
    letterSpacing: '0.09px',
    lineHeight: '19px',
    marginTop: '18px',
    marginBottom: '7px',
    fontFamily: 'Noto Sans JP',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '14px',
  },

  topTextStyleLeft: {
    color: `#6C7778`,
    textAlign: 'right',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: '400',
    letterSpacing: '0.07px',
  },


  topTextMutedStyle: {
    color: `#232F3E`,
    letterSpacing: '0px',
    lineHeight: '19px',
    marginTop: '12px',
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontSize: '14px',
  }

}))



export default useShippingImportStyles;
